<template>
  <section class="app-main">
    <keep-alive :include="['wh-search','creative_insight','game_insight','game_insight_appInfo']">
      <router-view :key="$route.fullPath" />
    </keep-alive>
  </section>
</template>

<script>
export default {
  name: "AppMain",
  computed: {
    key() {
      return this.$route.path;
    },
  },
};
</script>

<style lang="scss" scoped>

.loading-gap {
  height: 70px;
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>
