export default function () {
  //如果自定义列存在那么直接使用自定义列
  let baseCoulmns = _.clone(this.custom.columns);

  //如果不存在那么使用 不隐藏的列作为基础显示列
  if (!baseCoulmns.length) {
    _.forEach(this.tableColumnFields, (c, i) => {
      if (!this.tablePlugins?.hidden[c]) {
        baseCoulmns.push(c);
      }
    });
  }

  //拼凑固定在头部的列
  let fixedCoulmns = [];
  if (this.tablePlugins?.fixed) {
    _.forEach(this.tablePlugins.fixed, (v, k) => {
      if (v) {
        fixedCoulmns.push(k);
      }
    });
  }

  //因为表头中有部分列头使用的renderHeader，这种模式会导致渲染出问题
  //所以先将列头清空，在下一个 tick 中更新
  this.tableShowColumns = [];
  let tableShowColumns = _.uniq(
    _.concat(this.group.columns, fixedCoulmns, baseCoulmns)
  );

  //删除已经不存在的字段
  _.pullAll(
    tableShowColumns,
    _.difference(tableShowColumns, this.tableColumnFields)
  );

  // console.log(tableShowColumns)

  _.forEach(this.tableColumnFields, (c, i) => {
    if (!this.tablePlugins.fixed[c]) {
      //如果字段不属于 fixed 字段，才受到this.tableShowColumns的控制
      //根据业务需求 fixed 字段不允许通过custom调整显示顺序或是否显示
      if (tableShowColumns.includes(c)) {
        this.$set(this.tableColumnProps[c], "hidden", false);
        this.$set(this.tablePlugins.hidden, c, false);
        // }
      } else {
        this.$set(this.tableColumnProps[c], "hidden", true);
        this.$set(this.tablePlugins.hidden, c, true);
      }
    }

    //如果是group字段，那么必须显示并且是fixed
    if (this.group.columns.includes(c)) {
      this.$set(this.tableColumnProps[c], "hidden", false);
      this.$set(this.tablePlugins.hidden, c, false);
      this.$set(this.tableColumnProps[c], "fixed", true);
      this.$set(this.tablePlugins.fixed, c, true);
    }
  });

  this.$nextTick(() => {
    this.tableShowColumns = tableShowColumns;
  });
}
