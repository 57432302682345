import _ from 'lodash';

class ApiException {
  // 构造
  constructor(service, code, message, data, alarm) {
    this.code = code;
    this.message = message;
    this.data = data;
    this.service = service;
    this.alarm = alarm;
    return this;
  }

  notify(type) {
    let lang = { error: '错误', warning: '警告' }
    let message = `[${this.service}][${this.code}] ${this.message}`;

    this.alarm.notify(this.message, this.code, type, this.service);

    return this;
  }
}


// 需要注入的 http 实际为 axios 对象
class Api {
  // 构造
  constructor({ alarm, encrypt, pathGenerate, http }) {
    this.alarm = alarm;
    this.encrypt = encrypt;
    this.pathGenerate = pathGenerate;
    this.http = http;
    this.httpConfig = {}
    return this;
  }

  action(moduleName, method) {
    this.moduleName = moduleName;
    this.method = method;
    return this;
  }

  send(apipath, params, config = {}) {
    //默认关闭缓存
    config = _.assign({
      cache: false
    }, config)
    return this.http.post(apipath, params, config)
      .then((response) => {
        if(_.isFunction(config['callback'])) {
          config['callback'](response)
        }

        //为了兼容java接口穿透返回原始JSON/rawData
        if(response.headers["x-raw-data"]) {
          response.data["code"] = response.data["code"] ?? response.data["status"]
          response.data["message"] = response.data["message"] ?? response.data["error"]
          response.data["data"] = response.data["data"] ?? response.data["list"]
          response.data["type"] = 'BE'
        }

        let AE = new ApiException(
          'BE',
          response.data["code"],
          response.data["message"],
          response.data["data"],
          this.alarm
        );

        //根据后端type来判断错误类型
        AE.service = response.data["type"] || 'FE';

        switch (response.data["code"]) {
          case 200:
            return response.data["data"];
            break;

          case 401: //未登录 则跳转到首页
            if (AE.service == 'FE') { //如果是前端的401才跳转
              // document.location.href = '/';
            } else {
              AE.notify('warning');
            }
            break;

          case 422: //表单验证失败不弹出全局提示
            break;

          default: //其他报错均弹出全局提示
            AE.notify('warning');
        }

        throw AE;
      }).catch((error) => {
        if (error instanceof ApiException) {
          throw error;
        }
        throw new ApiException(
          'FE',
          error?.response?.status,
          error?.response?.data || error,
          false,
          this.alarm
        ).notify('error');
      });
  }

  async request() {
    //获取参数
    let args = Array.prototype.slice.call(arguments);

    let isUpload = false;

    let params = args;

    let apipath = this.pathGenerate.path(this.moduleName, this.method);

    let config = this.httpConfig

    //如果包含window对象说明在浏览器中，那么尝试分析根据是否有上传文件，改变参数类型
    if (window) {
      //加载Object转FormData工具
      await import('./object-traverse');
      await import('./object-to-formdata');

      //递归遍历数据中是否有 Blob 和 File 对象，标记为上传类型
      Object.traverse(args, function (node, value, key, path, depth) {
        if (value instanceof Blob || value instanceof File || value instanceof FileList) {
          isUpload = true;
          return false;
        }
      }, null, null, true);

      //如果确定为上传文件类型那么将数据转为 formdata 否则默认传输 json
      params = isUpload ? Object.toFormData(args) : args;
    }

    return this.send(apipath, params, config);
  }

  /**
   *
   * ES6模式
   * $api('module.aaa').bbb(p1, p2).then(...)
   *
   * ES5模式 不再支持
   * $api('module.aaa', 'bbb')(p1, p2).then(...)
   * or
   * let bbb = $api('module.aaa', 'bbb')
   * bbb(p1, p2).then(...)
   *
   * 对外API接口
   * @param {*} moduleName 必须 调用的模块名
   * @param {*} method 非必须 调用的方法名 如果填写则为 ES5 兼容模式
   * @returns 返回ES6 Proxy 或ES5 Promise
   */
  $api(moduleName, config = {}) {
    let api = this;
    return new Proxy({}, {
      get: function (target, method) {
        return function () {
          api.httpConfig = config
          return api.action(moduleName, method).request.apply(api, arguments);
        }
      }
    });
  }
}

export default Api;
