  //从对象中将变量导入到当前的符号表，与php的extract相同
  //https://stackoverflow.com/questions/59564400/php-extract-function-equivalent-to-nodejs-javascript
  function extract(data, where) {
    var g = where || (typeof global !== 'undefined' ? global : this);
    for (var key in data){
      if (data.hasOwnProperty(key)){
          g[key] = data[key];
      }
    }
  }

  //公式运算
  function formulaEval(__formula__, object, rowsLength = null) {
    extract(object)
    return eval(__formula__);
  }

  export default formulaEval
