<template>
  <div :class="{ 'has-logo': showLogo }">
    <logo v-if="showLogo" />
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeMenu"
        :unique-opened="true"
        :collapse-transition="false"
        mode="vertical"
      >
        <sidebar-item
          v-for="route in permission_routes"
          :key="route.id"
          :item="route"
        />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapState } from "pinia";
import SidebarItem from "./SidebarItem";

import Logo from "./Logo";
import { useSettingsStore, usePermissionStore } from "@/store/pinia";
export default {
  components: { SidebarItem, Logo },
  computed: {
    ...mapState(usePermissionStore, ["permission_routes"]),
    activeMenu() {
      const route = this.$route;
      let { path } = route;

      if (path.includes("wechatwarning")) {
        path = "/wechatwarning/admin";
      }
      if (path.includes("userDefined")) {
        path = "/userDefined/admin";
      }
      if (path.includes("audience")) {
        path = "/audience/admin";
      }
      if (path.includes("text")) {
        path = "/text/admin";
      }
      if (path.includes("insight/app")) {
        path = "/insight/app";
      }
      if (path.includes("app/")) {
        path = "/app/admin";
      }
      if (path.includes("advertiser")) {
        path = "/advertiser/admin";
      }
      if (path.includes("account")) {
        path = "/account/admin";
      }
      if (path.includes("userdata")) {
        path = "/userdata";
      }
      if (path.includes("tiktok")) {
        path = "/campaign/admin";
      }
      if (path.includes("role")) {
        path = "/role/admin";
      }
      if (path.includes("appsflyer")) {
        path = "/dataPivot/appsflyer/create";
      }
      if (path.includes("google")) {
        path = "/campaign/admin";
      }
      if (path.includes("e-online-celebrity/")) {
        path = "/e-online-celebrity/list";
      }
      if (route.path.includes("/game_insight/")) {
        path = "/game_insight";
      }
      if (route.path.includes("/template/")) {
        path = "/creation/list";
      }
      if (route.path.includes("/creation/")) {
        path = "/creation/list";
      }
      if (route.path.includes("/material-good-select")) {
        path = "/tiktok/material-good-select";
      }

      return path;
    },
    showLogo() {
      let settingsStore = useSettingsStore();
      return settingsStore.sidebarLogo;
    },
  },
};
</script>
