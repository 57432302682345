<template>
  <div class="platform-table-filters-columns">
    <template v-if="filters.items.length && filters.items[0].name">
      <el-tooltip :content="conditionFormat" placement="bottom">
        <el-dropdown
          split-button
          @click="filterMethod"
          @command="handleCommand"
          trigger="click"
        >
          <span class="max-length"
            ><i class="fa fa-filter"></i> {{ filters.name }}</span
          >
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(v, k) in filters.items"
              :key="k"
              :command="v"
              >{{ v.name }}</el-dropdown-item
            >
            <el-dropdown-item command="cancel" divided
              >取消筛选</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </el-tooltip>
    </template>
    <template v-else>
      <el-button icon="fa fa-filter" @click="filterMethod"></el-button>
    </template>
    <!-- 弹框 -->
    <el-dialog
      title="范围筛选"
      :visible.sync="dialog"
      width="50%"
      :close-on-click-modal="false"
    >
      <div class="mad-filters-content-a">
        <div class="name-article">
          <div class="left-area">筛选名称</div>
          <div class="right-area">
            <el-input v-model="name" placeholder="请输入保存名称" style="width: 50%;min-width: 300px;"> </el-input>
          </div>
        </div>
        <div class="relation-area">
          <div class="left-area"></div>
          <div class="right-area">
            <div class="text">条件间的关系</div>
            <div class="radio-select">
              <el-radio v-model="filterRule" label="and">且</el-radio>
              <el-radio v-model="filterRule" label="or">或</el-radio>
            </div>
          </div>
        </div>

        <el-row :gutter="20" v-for="(filter, i) in condition" :key="i">
          <el-col :span="8" style="padding-right: 0">
            <el-form class="rangeFilter" :inline="true">
              <el-form-item>
                <el-select
                  filterable
                  v-model="filter.column"
                  placeholder="范围筛选"
                  clearable
                  @change="columnChange(condition[i])"
                >
                  <el-option
                    v-for="(config, column) in columns"
                    :key="column"
                    :label="getLabel(column)"
                    :value="column"
                  ></el-option>
                  <template v-if="!columns['channel_common_campaignName']">
                    <el-option
                      key="channel_common_campaignName"
                      label="广告系列名称(渠道/通用)"
                      value="channel_common_campaignName"
                    ></el-option>
                  </template>
                </el-select>
              </el-form-item>
            </el-form>
          </el-col>
          <el-col :span="14">
            <!-- 滑动条模式 -->
            <template
              v-if="
                filters.ranges[filter.column] &&
                filters.ranges[filter.column].slider
              "
            >
              <el-slider
                v-model="filter.value"
                range
                :min="rangeSilderConfig(filter).min"
                :max="rangeSilderConfig(filter).max"
                :step="rangeSilderConfig(filter).step"
                :disabled="filter.column ? false : true"
                :debounce="500"
                :format-tooltip="rangeSilderConfig(filter).formatTooltip"
              ></el-slider>
            </template>
            <!-- 筛选框模式 -->
            <template v-else-if="filterOptions[filter.column]">
              <el-form class="rangeFilter" :inline="true">
                <el-form-item label="为" style="display: flex">
                  <el-tooltip
                    :content="getLabel(filter.column)"
                    placement="bottom"
                  >
                    <el-select
                      filterable
                      v-model="filter.value"
                      :collapse-tags="columns[filter.column].filter.multiple"
                      :multiple="columns[filter.column].filter.multiple"
                      :placeholder="getLabel(filter.column)"
                      :clearable="
                        columns[filter.column].filter.clearable == undefined
                          ? true
                          : columns[filter.column].filter.clearable
                      "
                    >
                      <template v-if="filter.column == 'roleName'">
                        <el-option
                          v-for="item in roleSelectList"
                          :key="item.roleId"
                          :label="item.roleName"
                          :value="item.roleId"
                        ></el-option>
                      </template>
                      <template v-else>
                        <el-option
                          v-for="item in filterOptions[filter.column]"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </template>
                    </el-select>
                  </el-tooltip>
                </el-form-item>
              </el-form>
            </template>
            <!-- 填空条模式 -->
            <template v-else>
              <el-form class="rangeFilter" :inline="true">
                <el-form-item>
                  <el-col :span="8">
                    <el-select
                      filterable
                      v-model="condition[i].condition"
                      placeholder="条件"
                      @change="conditionChange(condition[i])"
                    >
                      <el-option label=">" value=">"></el-option>
                      <el-option label="<" value="<"></el-option>
                      <el-option label="=" value="="></el-option>
                      <el-option label="范围" value="BETWEEN"></el-option>
                      <el-option label="包含" value="LIKE"></el-option>
                      <el-option label="排除" value="NOT LIKE"></el-option>
                    </el-select>
                  </el-col>
                  <template v-if="condition[i].condition == 'BETWEEN'">
                    <el-col :span="8">
                      <el-input
                        v-model="condition[i].value[0]"
                        clearable
                        @clear="condition[i].value[0] = undefined"
                      ></el-input>
                    </el-col>
                    <el-col :span="8">
                      <el-input
                        v-model="condition[i].value[1]"
                        clearable
                        @clear="condition[i].value[1] = undefined"
                      ></el-input>
                    </el-col>
                  </template>
                  <template v-else>
                    <el-col :span="16">
                      <el-input
                        v-model="condition[i].value"
                        clearable
                        @clear="condition[i].value = undefined"
                      ></el-input>
                    </el-col>
                  </template>
                </el-form-item>
              </el-form>
            </template>
          </el-col>
          <el-col :span="2">
            <el-button
              v-if="i > 0"
              type="primary"
              icon="el-icon-delete"
              circle
              @click="conditionDel(i)"
            ></el-button>
          </el-col>
        </el-row>

        <el-row style="text-align: left">
          <el-button
            type="primary"
            icon="el-icon-plus"
            size="mini"
            @click="conditionAdd"
          >
            新增筛选条件
          </el-button>
        </el-row>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-form :inline="true" @submit.native.prevent>
          <el-form-item style="float: left">
            <el-button type="danger" @click="remove">删 除</el-button>
          </el-form-item>
          <el-form-item>
            <el-button @click="dialog = false">取 消</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="save">{{
              isUpdate ? "保 存" : "新 建"
            }}</el-button>
          </el-form-item>
        </el-form>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import fastCopy from "fast-copy";
import { httpUserRoleRoleList } from "@/api/system";
export default {
  props: {
    columns: {},
    filterOptions: {},
    filters: {
      type: Object,
      default: function () {
        return {
          name: undefined, //当前表头
          filterRule:'and',
          condition: [
            { column: undefined, condition: undefined, value: undefined },
          ], //当前条件
          items: [], //筛选项
          ranges: {},
        };
      },
    },
  },
  data() {
    return {
      name: "",
      condition: [],
      dialog: false,
      dragging: false,
      conditionFormat: "",
      roleSelectList: [],
      filterRule: "and",
    };
  },
  computed: {
    isUpdate() {
      //如果name为空认为是新建
      return !this.name
        ? false
        : _.some(this.filters.items, { name: this.name,filterRule:this.filterRule });
    },
  },
  watch: {
    filters: {
      handler(filters) {

        this.name = filters.name;
        this.filterRule = filters.filterRule;
        this.$set(this, "condition", fastCopy(filters.condition));
      },
      immediate: true,
      deep: true,
    },
    condition() {
      this.conditionFormat = this.filtersFormatTag();
    },
    // name: {
    //   handler(v) {
    //     if(!v) { // 如果筛选名为空
    //       this.name = '临时筛选'
    //     }
    //   },
    //   immediate: true
    // },
  },
  methods: {
    filterMethod() {
      this.dialog = true;
    },
    handleCommand(v) {
      let name, condition,filterRule='and';
      if (v == "cancel") {
        name = undefined;
        condition = [
          { column: undefined, condition: undefined, value: undefined },
        ];
        sessionStorage.removeItem(
          this.getPlatformTable().id.split("admin:")[1]
        );
      } else {
        name = v.name;
        condition = v.condition;
        filterRule = v.filterRule || 'and';
      }
      this.$set(this.getPlatformTable().filters, "name", name);
      this.$set(this.getPlatformTable().filters, "condition", condition);
      this.$set(this.getPlatformTable().filters, "filterRule", filterRule);
      this.$emit("handleSelectFilter", condition,filterRule);
    },
    getPlatformTable() {
      return this.$parent.$parent.$parent;
    },
    getLabel(column) {
      try {
        return this.getPlatformTable().getColumnLabel(column);
      } catch {
        return column;
      }
    },
    remove() {
      if (!this.name) {
        this.$message({
          type: "info",
          message: "未找到需要删除的过滤器",
        });
        return false;
      }

      this.$confirm(`是否删除${this.name}？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$emit("remove", this.name);
          this.dialog = false;
          this.$message({
            type: "success",
            message: "删除成功",
          });
          this.$emit("handleSelectFilter", []);
        })
        .catch(() => {});
    },
    save() {
      if (!_.trim(this.name)) {
        this.$message({
          type: "error",
          message: "请填写筛选名称",
        });
        return false;
      }

      this.$emit("save", this.name, this.condition,this.filterRule);
      this.dialog = false;
    },

    columnChange(filter) {
      // console.log(this.filterOptions);
      if (this.filters.ranges[filter.column]?.slider) {
        let nx = this.rangeSilderConfig(filter);
        filter.condition = "BETWEEN";
        filter.value = [nx.min, nx.max];
      } else if (this.filterOptions[filter.column]) {
        filter.condition = "LIKE";
        filter.value = undefined;
      } else {
        filter.condition = undefined;
        filter.value = undefined;
      }
    },
    conditionChange(filter) {
      if (filter.condition == "BETWEEN") {
        let nx = this.rangeSilderConfig(filter);
        filter.value = [undefined, undefined];
      } else {
        filter.value = undefined;
      }
      // console.log(filter);
    },

    rangeSilderConfig(filter) {
      return _.assign(
        {
          min: this.filters.ranges[filter.column]?.min,
          max: this.filters.ranges[filter.column]?.max,
          step: this.filters.ranges[filter.column]?.step,
          formatTooltip: (f) => {
            // console.log(filter, f);
            try {
              return this.tablePlugins.format[filter.column](f);
            } catch (e) {}
          },
        },
        this.filters.ranges[filter.column]
      );
    },

    filtersFormatTag() {
      let filtersTags = [];
      _.forEach(this.condition, (f) => {
        filtersTags.push(this.filterConditionFormat(f));
      });

      return filtersTags.length > 0 ? "筛选 " + filtersTags.join(", ") : "";
    },

    //查询标签显示格式化
    filterConditionFormat(filter) {
      let tps = this.getPlatformTable().tablePlugins;
      let cn = this.getLabel(filter.column);
      let format =
        tps.format[filter.column] ||
        function (f) {
          return f;
        };

      if (filter.condition == "LIKE") {
        return `${cn} 选项为 ${format(filter.value)}`;
      } else if (filter.condition == "BETWEEN") {
        return `${cn} (${format(filter.value[0])} - ${format(
          filter.value[1]
        )})`;
      } else if (filter.condition == "LIKE") {
        return `${cn} 包含 ${format(filter.value)}`;
      } else if (filter.condition == "NOT LIKE") {
        return `${cn} 排除 ${format(filter.value)}`;
      } else if (filter.condition == ">") {
        return `${cn} > ${format(filter.value)}`;
      } else if (filter.condition == "<") {
        return `${cn} < ${format(filter.value)}`;
      } else if (filter.condition == "=") {
        return `${cn} = ${format(filter.value)}`;
      }
    },

    conditionAdd() {
      this.condition.push({
        column: undefined,
        condition: undefined,
        value: undefined,
      });
    },

    conditionDel(index) {
      this.condition.splice(index, 1);
      if (this.condition.length == 0) {
        this.conditionReset();
      }
    },

    conditionReset() {
      this.condition = [
        {
          column: undefined,
          condition: undefined,
          value: undefined,
        },
      ];
    },
    // 获取角色列表数据
    async handleRoleList() {
      let { path } = this.$route;
      if (path == "/userdata/dashboard") {
        return;
      }

      let reuslt = await httpUserRoleRoleList();
      let list = reuslt.list;
      this.roleSelectList = list;
    },
  },

  async mounted() {
    // 暂时注释可能有后遗症

    // await this.handleRoleList();
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/var.scss";
.rangeFilter .el-form-item.el-form-item--small{
  width: 100%;
  > ::v-deep(.el-form-item__content) {
    width: 100%;
  }
}
.platform-table-filters-columns {
  .max-length {
    max-width: 90px;
    white-space: nowrap;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }

  .el-dialog {
    .el-dialog__body {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
  .dailog-body {
    .col-height {
      height: 45vh;
    }
    .tree-height {
      height: calc(100% - 46px);
      overflow: auto;
    }
    .card-ghost {
      opacity: 0.8;
      color: #fff;
      background: $--color-primary;
    }
    .el-card {
      cursor: move;
      margin: 0 5px 5px 5px;
      .el-card__body {
        line-height: initial;
        padding: 10px;
      }
      .close {
        float: right;
        cursor: pointer;
      }
    }
  }
  .mad-filters-content-a {
    .name-article {
      display: flex;
      margin-bottom: 15px;
      .left-area {
        width: 80px;
      }
      .right-area {
        flex: 1;
      }
    }
    .relation-area {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      .right-area {
        margin-right: 10%;
        display: flex;
        gap: 15px;
      }
    }
  }
}
</style>
