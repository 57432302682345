<template>
  <div>
    <div v-if="editStatus">
      <el-dialog
        v-if="mode == 'popup'"
        :title="title"
        :visible.sync="editStatus"
        :append-to-body="true"
        width="30%"
      >
        <el-input
          v-if="type == 'input'"
          v-model="inputValue"
          @keyup.enter.native="save"
        ></el-input>
        <el-input
          v-if="type == 'textarea'"
          type="textarea"
          v-model="inputValue"
        ></el-input>
        <el-select filterable 
          v-if="type == 'select'"
          v-model="inputValue"
          :multiple="multiple"
          :disabled="disabled"
        >
          <slot></slot>
        </el-select>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editStatus = false">取 消</el-button>
          <el-button type="primary" @click="save">确 定</el-button>
        </span>
      </el-dialog>
      <span v-if="mode == 'inline'">
        <el-input
          v-if="type == 'input'"
          ref="inline_input"
          v-model="inputValue"
          @keyup.enter.native="save"
          @blur="editStatus = false"
          @change="save"
          size="mini"
        ></el-input>
        <el-input
          v-if="type == 'textarea'"
          ref="inline_input"
          type="textarea"
          v-model="inputValue"
          @blur="editStatus = false"
          @change="save"
        ></el-input>
        <el-select filterable 
          v-if="type == 'select'"
          ref="inline_select"
          size="mini"
          v-model="inputValue"
          :multiple="multiple"
          :disabled="disabled"
          @visible-change="visibleChange"
        >
          <slot></slot>
        </el-select>
      </span>
    </div>
    <div v-show="originDisplay" :class="contentClass" @click="edit">
      <slot name="content">
        <span :class="disabled ? 'edit-not-allowed':'edit-allowed'">{{
          content ? content : value
        }}</span>
      </slot>
    </div>
  </div>
</template>
<style scoped>
.edit-allowed {
  border-bottom: 1px dashed;
  cursor: pointer;
}
.edit-not-allowed {
  border-bottom: 1px dashed;
  cursor: not-allowed;
}
</style>
<script>
export default {
  props: {
    mode: {
      type: String,
      default: "inline",
    },
    title: {
      required: false,
      default: "",
    },
    data: {
      required: false,
    },
    column: {
      required: false,
    },
    type: {
      type: String,
      default: "input",
    },
    value: {
      required: true,
    },
    content: {
      required: false,
    },
    contentClass: {
      type: String,
      required: false,
    },
    options: {
      required: false,
    },
    multiple: {
      required: false,
    },
    disabled: {
      required: false,
    },
  },

  provide: function () {
    return {
      select: this,
    };
  },

  computed: {
    originDisplay: function () {
      return this.editStatus && this.mode == "inline" ? false : true;
    },
  },
  methods: {
    edit: function () {
      if(this.disabled) {
        return;
      }

      this.editStatus = true;
      this.$nextTick(() => {
        if (this.$refs.inline_input) {
          this.$refs.inline_input.focus();
        }

        if (this.$refs.inline_select) {
          this.$refs.inline_select.focus();
        }
      });
    },
    visibleChange: function (event) {
      //隐藏时触发
      if (event === false) {
        this.save();
      }
    },
    save: function () {
      this.editStatus = false;

      //避免未修改的内容抛出事件造成性能损失
      if (this.inputValue != this.value) {
        let done = (status) => {
          let v = status ? this.inputValue : this.value
          this.$emit("input", v);
          this.$emit("afterSave", this.inputValue, this.value, this.data, status);
          this.$nextTick(() => {
            //成功的值给 inputValue，如果失败了用老的值
            this.inputValue = v
          })
        }

        //传入完成函数，参数为是否修改成功 true 陈功 false 不成功
        this.$emit("save", this.inputValue, this.value, this.data, done);
      }
    },
    cancel: function () {},
  },
  watch: {
    value: {
      handler: function (v) {
        this.inputValue = v;
      },
      immediate: true,
    },
  },
  data: function () {
    return {
      inputValue: null,
      editStatus: false,
    };
  },
};
</script>
