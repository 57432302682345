// var axios = require('axios');
import axios from 'axios';
var instance = axios.create({
	  headers: {'X-Requested-With': 'XMLHttpRequest'}
});
export default {
	install : function (Vue, options) {
		Vue.prototype.$http = instance;
	},
	instance,
  'axios' : instance
}
