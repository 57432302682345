<template>
  <div class="platform-table" :class="treeClass">
    <div class="clearboth">
      <slot name="table-top">
        <div class="top-left">
          <slot name="table-top-left"></slot>
        </div>
        <div class="top-right">
          <slot name="table-top-right">
            <el-form :inline="true" @submit.native.prevent>
              <slot name="before-table-filters"></slot>
              <!-- 顶部右侧过滤器 如果 字段配置 为 true 那么 使用自动过滤，否则使用自定义过滤-->
              <slot name="table-filters">
                <template v-for="(filter, column) in tablePlugins.filter">
                  <el-form-item v-if="filter && filter.show">
                    <el-tooltip
                      :content="tableColumnNames[column]"
                      placement="bottom"
                    >
                      <el-select
                        filterable
                        v-model="query.columns[column]"
                        :collapse-tags="filter.multiple"
                        :multiple="filter.multiple"
                        :placeholder="tableColumnNames[column]"
                        style="width: 150px"
                        @change="
                          handleUpStatusChange(column, query.columns[column])
                        "
                        :clearable="
                          filter.clearable == undefined
                            ? true
                            : filter.clearable
                        "
                      >
                        <el-option
                          v-for="item in filterOptions[column]"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </el-tooltip>
                  </el-form-item>
                </template>
              </slot>

              <!-- 可保存筛选组 -->
              <el-form-item v-if="tablePlugins.filters && id">
                <component-filters-columns
                  @remove="filtersConditionRemove"
                  @save="filtersConditionSave"
                  @handleSelectFilter="handleSelectFilter"
                  :filters="filters"
                  :filterOptions="filterOptions"
                  :columns="tableColumnProps"
                ></component-filters-columns>
              </el-form-item>

              <!-- 搜索插件 -->
              <el-form-item v-if="tablePlugins.search">
                <el-input
                  @input="debounceInput"
                  v-model="fullTextSearch"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  v-if="
                    tablePlugins.search ||
                    Object.keys(tablePlugins.filter).length > 0
                  "
                  title="Reset"
                  @click="queryReset"
                >
                  <i class="fa fa-times"></i>
                </el-button>
              </el-form-item>

              <slot name="after-table-filters"></slot>
              <el-form-item v-if="tablePlugins.custom && id">
                <component-custom-columns
                  @remove="customHeaderRemove"
                  @save="customColumnsSave"
                  :custom="custom"
                  :columns="tableColumnProps"
                ></component-custom-columns>
              </el-form-item>
              <el-form-item v-if="tablePlugins.group && id">
                <component-group-columns
                  v-model="group.columns"
                  :columns="tableColumnProps"
                ></component-group-columns>
              </el-form-item>
              <template v-if="tablePlugins.export">
                <el-form-item>
                  <el-button @click="exportData">
                    <i class="fa fa-download"></i>
                  </el-button>
                </el-form-item>
              </template>
            </el-form>
          </slot>
        </div>
        <div class="clearfix"></div>
      </slot>
    </div>
    <component
      :is="vtable"
      v-bind="tableOptions"
      ref="elementTable"
      :data="tableRows"
      @sort-change="sortChange"
      :summary-method="summaryMethod"
      :show-summary="showSummary"
      @select="handleSelect"
      @select-all="handleSelectAll"
      @header-dragend="
        (newWidth, oldWidth, column, event) => {
          headerDragend(newWidth, oldWidth, column, event);
        }
      "
    >
      <component
        :is="vtableColumn"
        v-if="tableOptions.childRow"
        type="expand"
        fixed="left"
      >
        <template slot-scope="props">
          <component
            v-bind:is="tableOptions.childRow"
            :data="props"
          ></component>
        </template>
      </component>
      <component
        :is="vtableColumn"
        v-if="tablePlugins.radio"
        width="45"
        align="center"
        header-align="center"
        fixed="left"
      >
        <template slot-scope="props">
          <input type="radio" v-model="radioChecked" :value="props.row" />
        </template>
      </component>
      <component
        :is="vtableColumn"
        width="45"
        align="center"
        header-align="center"
        v-if="tablePlugins.checkbox"
        type="selection"
        fixed="left"
      ></component>
      <component
        :is="vtableColumn"
        v-for="(column, index) in tableShowColumns"
        v-if="!(tablePlugins.hidden && tablePlugins.hidden[column])"
        v-bind="tableColumnProps[column]"
        :width="columnWitdh(tableColumnProps[column])"
        :key="index"
        :prop="column"
        :tree-node="group.columns.indexOf(column) == 0"
      >
        <template slot-scope="props">
          <!-- <div class="table-flex-box"> -->
          <span class="table-cell-span">
            <slot :name="column" v-bind="props">
              <template
                v-if="tablePlugins.switch && tablePlugins.switch[column]"
              >
                <!-- 如果开关插件存在-->
                <el-switch
                  v-model="props.row[column]"
                  :width="40"
                  :active-value="tablePlugins.switch[column].value.on"
                  :inactive-value="tablePlugins.switch[column].value.off"
                  :active-color="
                    tablePlugins.switch[column].activeColor || '#409EFF'
                  "
                  @change="switchChange(props, column)"
                  :disabled="
                    tablePlugins.switch[column].disabled
                      ? tablePlugins.switch[column].disabled(props)
                      : false
                  "
                ></el-switch>
              </template>
              <template
                v-else-if="tablePlugins.edit && tablePlugins.edit[column]"
              >
                <span :title="cellIterator(props.row, column)">
                  <platform-xeditable
                    v-bind="tablePlugins.edit[column]"
                    @save="tablePlugins.edit[column].save"
                    @afterSave="tablePlugins.edit[column].afterSave"
                    :content="cellIterator(props.row, column)"
                    :data="{
                      column: column,
                      row: props.row,
                      target: $el.querySelector(
                        `.target_${column}_${props.$index}:not(.is-hidden)`
                      ),
                    }"
                    v-model="props.row[column]"
                    :disabled="
                      tablePlugins.edit[column].disabled
                        ? tablePlugins.edit[column].disabled(props)
                        : false
                    "
                  >
                    <el-option
                      v-for="item in tablePlugins.edit[column].options"
                      v-if="tablePlugins.edit[column].type == 'select'"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </platform-xeditable>
                </span>
              </template>
              <template
                v-else-if="tablePlugins.format && tablePlugins.format[column]"
              >
                <span>{{ cellIterator(props.row, column) }}</span>
              </template>
              <template v-else>
                <span :title="props.row[column]">{{
                  cellIterator(props.row, column)
                }}</span>
              </template>
            </slot>
          </span>
          <template v-if="tablePlugins.menu && tablePlugins.menu[column]">
            <el-dropdown
              trigger="click"
              size="medium"
              :show-timeout="0"
              :hide-timeout="0"
            >
              <span class="el-dropdown-link">
                <i class="fa fa-cog link-mode"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <div
                  v-for="menu in tablePlugins.menu[column](props)"
                  :key="menu.text"
                  @click.middle="
                    menuItemClick({ fn: menu.click, props: props, popup: 1 })
                  "
                  @click="
                    menuItemClick({ fn: menu.click, props: props, popup: 0 })
                  "
                >
                  <el-tooltip
                    :content="menu.title"
                    :disabled="menu.title ? false : true"
                  >
                    <!-- :command="{fn:menu.click, props:props}" -->
                    <el-dropdown-item
                      :divided="menu == '-' ? true : false"
                      :disabled="menu.disabled ? true : false"
                    >
                      <template v-if="menu != '-'">
                        <i :class="menu.icon || 'glyphicon glyphicon-none'"></i>
                        {{ menu.text }}
                      </template>
                    </el-dropdown-item>
                  </el-tooltip>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
          <!-- </div> -->
        </template>
      </component>
      <slot></slot>
      <template slot="empty">
        <div class="flex-column table-nodata">
          <span v-if="loading">数据正在请求中</span>
          <span v-else>暂无数据</span>
        </div>
      </template>
    </component>
    <el-pagination
      v-if="newTotal > 0 && isGroup && bPageShow"
      @size-change="sizeChange"
      @current-change="currentChange"
      :current-page.sync="MyCurrentPage"
      :page-sizes="[20, 50, 100, 500, 1000]"
      :page-size="parentPageSize"
      layout="sizes, ->, slot, total, prev, pager, next, jumper"
      :total="newTotal"
      background
    ></el-pagination>
  </div>
</template>

<script>
import "./style.scss"; // global css
import fastCopy from "fast-copy";
import props from "./props.js";
import exportData from "./methods/exportData.js";
import initTablePlugins from "./methods/initTablePlugins.js";
import dataTriggerPlugins from "./methods/dataTriggerPlugins.js";
import columnsTriggerPlugins from "./methods/columnsTriggerPlugins.js";
import showColumnsTrigger from "./methods/showColumnsTrigger.js";
import summaryStatistics from "./methods/summaryStatistics.js";
import collectionGroup from "./methods/collection.group.js";
import nestedSort from "./methods/nestedSort.js";
import cellIterator from "./methods/cellIterator.js";
import filtering from "./methods/filteringPagination.js";
import showFormulaColumnsEval from "./methods/showFormulaColumnsEval.js";
import MixinCustom from "./mixins/custom/index.js";
import MixinGroup from "./mixins/group/index.js";
import MixinFilters from "./mixins/filters/index.js";
import MixinClient from "./mixins/client/index.js";
import { UTable, UTableColumn } from "umy-ui";
import { vbtTable, vbtTableColumn } from "vbt-table";
import hashsum from "hash-sum";
import { v4 as uuidv4 } from "uuid";
import { mapState } from "pinia";

import { getUniqueRandomNumber } from "@/utils/util";
import { useSettingsStore } from "@/store/pinia";
export default {
  name: "indexPagination",
  props,
  mixins: [MixinCustom, MixinGroup, MixinFilters, MixinClient],
  components: {
    UTable,
    UTableColumn,
    vbtTable,
    vbtTableColumn,
  },
  data: function () {
    return {
      MyCurrentPage: 1,
      bPageShow: false,
      treeClass: "",
      vtableColumn: "",
      vtable: "",
      rows: [],
      dataRows: [],
      filterRows: [],
      tableRows: [],
      sort: {
        column: null,
        order: null,
      },
      showSummary: false,
      summaryData: {},
      checkboxChecked: [],
      radioChecked: null,
      tableShowColumns: [],
      tableDefalutShowColumns: [],
      tableColumnFields: [],
      tableColumnNames: {},
      tableColumnProps: {},
      tableOptions: {},
      tablePlugins: {},
      filterOptions: {},
      fullTextSearch: "",
      query: {
        columns: {},
        filters: [
          {
            condition: undefined,
            column: undefined,
            value: undefined,
          },
        ],
        keywords: [],
        fullTextSearch: null,
      },
      uniqId: (() => {
        // this.plugins.uniqueKey = this.plugins.uniqueKey || "id";
        // return this.plugins.uniqueKey;
      })(),
    };
  },
  computed: {
    ...mapState(useSettingsStore, {
      loading: (store) => store.isLoading,
    }),
    primaryKey() {
      //如果
      if (this.tablePlugins.uniqueKey) {
        return _.isArray(this.tablePlugins.uniqueKey)
          ? this.tablePlugins.uniqueKey
          : [this.tablePlugins.uniqueKey];
      }

      return null;
    },

    columnWitdh() {
      return function (record) {
        if (this.handleIsCampaignRoute()) {
          let columnWidthObj = this.handleGetColumnWidthObj();
          let { column, label } = record;
          let customWIdth = [
            "channel_common_groupName",
            "channel_common_platformName",
            "channel_common_accountName",
            'channel_common_campaignName',
            'channel_common_adsetName',
            'channel_common_adName'
          ];
          if (!columnWidthObj[label] && customWIdth.includes(column)) {
            return "220px";
          }
          return columnWidthObj[label];
        } else {
          return;
        }
      };
    },
    tableAllRows() {
      let data = this.filterRows;

      //根据排序字段和排序顺序对完整数据排序
      if (this.sort.column && this.sort.order) {
        // data = _.orderBy(data, [this.sort.column], [this.sort.order]);
        data = nestedSort(data, this.sort.column, this.sort.order);
      }

      return data;
    },
  },
  mounted() {
    let vm = this;
    this.$watch(
      (vm) => (vm.tableAllRows, Date.now()),
      () => {
        this.tableRows = this.tableAllRows ? this.tableAllRows : [];
      }
    );
    this.$nextTick(() => {
      this.bPageShow = true;
    });
  },
  created() {
    this.tabelInnit();
  },
  methods: {
    headerDragend(newWidth, oldWidth, column, event) {
      if (this.handleIsCampaignRoute()) {
        let { label } = column;
        let columnWidthObj = this.handleGetColumnWidthObj();
        columnWidthObj[label] = newWidth + "px";
        localStorage.setItem("columnWidthObj", JSON.stringify(columnWidthObj));
      }
    },
    handleIsCampaignRoute() {
      let isDropwidhtRouter = ["/campaign/admin", "/admaterial"];

      return isDropwidhtRouter.includes(this.$route.path);
    },
    handleGetColumnWidthObj() {
      return JSON.parse(localStorage.getItem("columnWidthObj") || "{}");
    },
    // 新增需求判断是否是数组

    //判断是使用umy还是vbt
    tabelInnit() {
      // if (!this.plugins?.useTree) {
      //   this.treeClass = "umyStyle";
      // } else {
      //   this.treeClass = "tree-box";
      // }
      let tabelAsync;
      if (!this.plugins?.useTree) {
        this.treeClass = "umyStyle";
        tabelAsync = {
          // "row-id": "rowkey",
          fixedColumnsRoll: true,
          border: true,
          treeConfig: {
            children: "children",
            expandAll: this.tableOptions?.expandAll
              ? this.tableOptions.expandAll
              : false,
          },
          useVirtual: true,
          "row-height": this.tableOptions.rowHeight,
          cxy: 1213,
        };
        this.vtable = "UTable";
        this.vtableColumn = "UTableColumn";
      } else {
        this.treeClass = "tree-box";
        tabelAsync = {
          isBigData: true,
          isTreeTable: true,
          rowKey: "rowkey",
          size: "mini",
        };
        this.vtable = "vbtTable";
        this.vtableColumn = "vbtTableColumn";
      }
      this.tableOptions = Object.assign(this.tableOptions, tabelAsync);

      this.tableOptions.cellClassName = ({ column, rowIndex }) => {
        return "target_" + this.getCellKey(column.property, rowIndex);
      };
    },
    reset() {
      this.queryReset();
      this.pageReset();
      this.clearCheckboxChecked();
      this.resetSort();
    },
    pageReset() {},
    resetSort() {
      this.$refs.elementTable.clearSort();
      let defaultSort = this.options.defaultSort;
      if (defaultSort) {
        this.$refs.elementTable.sort(defaultSort.prop, defaultSort.order);
      }
    },
    //勾选记录
    setRadioChecked: function (row) {
      this.$nextTick(() => {
        this.radioChecked = _.find(this.rows, row);
      });
    },
    //获取已经勾选的记录
    getRadioChecked: function () {
      return this.radioChecked;
    },

    //使用数据勾选记录
    setCheckboxChecked: function (rows) {
      //通过 数据集片段 筛选 得到完整数据集记录
      //先清除勾选记录
      this.$refs.elementTable.clearSelection();
      this.checkboxChecked = this.inRowsByPk(rows, this.rows);

      if (_.size(this.checkboxChecked)) {
        let rows = !this.plugins?.useTree
          ? this.$refs.elementTable.getUTreeData()
          : this.rows;

        let checkboxChecked = this.inRowsByPk(this.checkboxChecked, rows);

        if (checkboxChecked) {
          if (!this.plugins?.useTree) {
            //如果不是柱状结构表
            checkboxChecked.forEach((row) => {
              //备注此项是element ui写法-----****不可删除
              // this.$refs.elementTable.toggleRowSelection(row, true);
              this.$refs.elementTable.toggleRowSelection([{ row }]);
            });
          } else {
            //树结构且不带开关
            checkboxChecked.forEach((row) => {
              this.$refs.elementTable.toggleRowSelection(row, true);
            });
          }
        }
      }
    },
    //获取已经勾选的记录
    getCheckboxChecked: function () {
      return this.checkboxChecked;
    },
    // 清空checked的记录
    clearCheckboxChecked() {
      this.$refs.elementTable.clearSelection();
      this.checkboxChecked = [];
    },
    handleSelect: function (selection) {
      //当前表格已选择的选项
      let selected = this.getRowsKeyItem(selection);
      // console.log(selected, '当前已选择')

      //当前表格未选择的选项
      // let unselected = this.exRowsByPk(selection, this.getRowsKeyItem(this.tableRows);
      let unselected = this.exRowsByPk(
        selected,
        this.getRowsKeyItem(this.tableRows)
      );
      // console.log(unselected, '当前未选择')

      //全部已选择的
      let allSelected = this.getRowsKeyItem(this.checkboxChecked);
      // console.log(allSelected, '历史选择')

      allSelected = _.unionWith(selected, allSelected, _.isEqual);
      // console.log(allSelected, '合并当前选择和历史选择')

      //排除当前未选择
      allSelected = this.exRowsByPk(unselected, allSelected);
      // console.log(allSelected, '排除当前未选择')

      // console.time('checkboxChecked')
      //根据所有勾选过的keyitem得到勾选的数据
      this.checkboxChecked = this.inRowsByPk(allSelected, this.rows);
      // console.timeEnd('checkboxChecked')
    },
    handleSelectAll: function (selection) {
      this.handleSelect(selection);
      // this.handleSelect(rows)
    },
    getNodeText: function (node) {
      if (node.children) {
        return _.map(node.children, (children) => {
          return children ? this.getNodeText(children) : node.text;
        }).join("");
      } else {
        return node.text;
      }
    },
    getColumnName: function (column) {
      //如果 label 定义 那么读取 label
      if (this.tableColumnProps[column].label) {
        return this.tableColumnProps[column].label;
      }

      //如果 label 未定义 定义了 renderHeader 那么返回 vnode 的文本信息
      if (this.tableColumnProps[column].renderHeader) {
        let vnode = this.tableColumnProps[column].renderHeader.call(
          this,
          this.$createElement,
          { column: { property: column } }
        );
        if (typeof vnode == "object") {
          return this.getNodeText(vnode);
        } else {
          return vnode;
        }
      }

      return column;
    },
    getColumnCategory: function (column) {
      return this.tableColumnProps[column] &&
        this.tableColumnProps[column].category
        ? `(${this.tableColumnProps[column].category.replaceAll(".", "/")})`
        : "";
    },
    getColumnLabel: function (column) {
      return `${this.getColumnName(column)}${this.getColumnCategory(column)}`;
    },
    renderHeaderWrapper: function (render, columnProps = {}) {
      if (this.$router.currentRoute.path === "/role/roleAdmin") {
        if (columnProps?.sortable == true) {
          columnProps.sortable = "custom";
        }
      } else {
        columnProps.sortable = "custom";
      }

      return (h, { column, $index }) => {
        let category,
          categoryText = "";
        if (columnProps.category) {
          //如果分组存在那么显示分组
          categoryText = `(${columnProps.category.replaceAll(".", "/")})`;
          category = <div class="table-column-category">{categoryText}</div>;
        }
        return (
          <div
            class="table-column-render"
            title={`${this.getNodeText(
              render(h, { column, $index })
            )} ${categoryText}`}
          >
            <div>{render(h, { column, $index })}</div>
            {category}
          </div>
        );
      };
    },
    defaultRenderHeader: function (column, i) {
      //vnode
      //为了兼容1.x版本中表头渲染在 options配置 headings
      let heading = (this.tableOptions.headings || {})[column];
      let columnName = column;
      if (heading) {
        if (typeof heading == "function") {
          return heading.bind(this, this.$createElement, column);
        } else {
          columnName = heading;
        }
      }

      return function (h, { column, $index }) {
        return <span>{_.upperFirst(columnName)}</span>;
      };
    },
    switchChange: function (props, column) {
      var switchOpt = _.extend(
        {
          value: {
            on: true,
            off: false,
          },
          change: {
            on: function () {},
            off: function () {},
          },
        },
        this.tablePlugins.switch[column]
      );

      props.target = this.$el.querySelector(
        ".target_" + this.getCellKey(column, props.$index) + ":not(.is-hidden)"
      );

      //开启完成后执行动作
      let onDone = (status) => {
        let query;
        if (this.primaryKey) {
          let row = props.row;

          if (status) {
            //如果修改成功那么显示新的值否则显示老的值
            row[column] = switchOpt.value.on;
          } else {
            row[column] = switchOpt.value.off;
          }

          query = _.pick(row, this.primaryKey);
          let findRow = _.find(this.filterRows, query);
          delete row[`${column}_format`];
          findRow[column] = row[column];
          // this.tableRows.splice(0,0)
          this.$refs.elementTable.doLayout();
        } else {
          this.$message.error("Table primary key not found.");
        }
      };

      let offDone = (status) => {
        let query;
        if (this.primaryKey) {
          let row = props.row;

          if (status) {
            //如果修改成功那么显示新的值否则显示老的值
            console.log(column, row[column], switchOpt.value.off);
            row[column] = switchOpt.value.off;
          } else {
            row[column] = switchOpt.value.on;
          }

          query = _.pick(row, this.primaryKey);
          let findRow = _.find(this.filterRows, query);
          delete row[`${column}_format`];
          findRow[column] = row[column];
          // this.tableRows.splice(0,0)
          this.$refs.elementTable.doLayout();
        } else {
          this.$message.error("Table primary key not found.");
        }
      };

      if (props.row[column] == switchOpt.value.on) {
        switchOpt.change.on(props, onDone);
      } else if (props.row[column] == switchOpt.value.off) {
        switchOpt.change.off(props, offDone);
      }
    },
    menuItemClick: function ({ fn: fn, props: props, popup: popup }) {
      if (typeof fn == "function") {
        fn(props, popup);
      } else if (typeof fn == "string") {
        if (popup) {
          window.open(fn);
        } else {
          window.location.href = fn;
        }
      }
    },
    currentChange: function (page) {
      this.$emit("handleChangeCurrentPage", page);
    },
    handleUpStatusChange(key, value) {
      this.$emit("handleUpStatusChange", key, value);
    },
    sizeChange: function (pageSize) {
      this.$emit("handleChangePageSize", pageSize); //sel
    },
    sortChange: function ({ column: column, prop: prop, order: order }) {
      let sort_field = column?.property;
      let sortMap = {
        ascending: "ASC",
        descending: "DESC",
        null: null,
      };
      let sort_order = sortMap[order];

      this.$emit("handleChangeSort", sort_field, sort_order); //sel
    },
    summaryMethod: function ({ columns }) {
      let sum = [];
      this.summaryData = this.totalField;

      columns.forEach((column, index) => {
        if (this.tablePlugins.summaryFormat[column.property]) {
          sum[index] = this.tablePlugins.summaryFormat[column.property](
            this.summaryData[column.property],
            this.summaryData
          );
          if (sum[index] == 0) {
            sum[index] = this.thirdDataTotalField[column.property];
          }
        } else {
          sum[index] = this.summaryData[column.property];
          if (sum[index] == 0) {
            sum[index] = this.thirdDataTotalField[column.property];
          }
        }
      });
      if (!this.plugins?.useTree) {
        return [sum];
      } else {
        return sum;
      }
    },
    debounceInput: _.debounce(function () {
      this.$emit(
        "handleChangeFullTextSearch",
        this.fullTextSearch,
        this.tableShowColumns
      );
    }, 300),
    //重置搜索
    queryReset: function () {
      this.query["keywords"] = [];
      this.query["fullTextSearch"] = null;
      // 新增充值搜索条件 然后调用接口
      this.fullTextSearch = "";
      this.$emit("handleResetSearch");
    },

    //获取某列数据最大及最小值
    columnDataRange: function (column) {},

    //查询 filters 数据重置
    queryFiltersReset() {
      this.query.filters = [
        {
          column: undefined,
          condition: undefined,
          value: undefined,
        },
      ];
    },

    //获取行key
    getRowKey(row) {
      return hashsum(_.pick(row, this.primaryKey));
    },

    //获取单元格key
    getCellKey(column, rowIndex) {
      return `${column}_${rowIndex}`;
    },

    //获取指定数据行的key item 数据集
    getRowsKeyItem(rows) {
      //https://stackoverflow.com/questions/37877860/lodash-pick-object-fields-from-array
      return _.map(rows, _.partialRight(_.pick, this.primaryKey));
    },

    //根据主键获取匹配的数据集
    inRowsByPk(findRows, allRows) {
      let subFindRows = this.getRowsKeyItem(findRows);

      // return _.intersectionWith(
      //   allRows,
      //   subFindRows,
      //   _.isMatch
      // );

      //下面写法是为了优化性能，对于已经匹配的值删除，不再循环，主键需要是唯一值
      return _.filter(allRows, (row) => {
        for (let i in subFindRows) {
          if (_.isMatch(row, subFindRows[i])) {
            delete subFindRows[i];
            return true;
          }
        }

        return false;
      });
    },

    //根据主键获取排除的数据集
    exRowsByPk(findRows, allRows) {
      let subFindRows = this.getRowsKeyItem(findRows);

      // return _.differenceWith(
      //   allRows,
      //   subFindRows,
      //   _.isMatch
      // );
      //下面写法是为了优化性能对于，已经匹配的值删除，不再循环，主键需要是唯一值
      return _.filter(allRows, (row) => {
        for (let i in subFindRows) {
          if (_.isMatch(row, subFindRows[i])) {
            delete subFindRows[i];
            return false;
          }
        }

        return true;
      });
    },

    afterSave: function (val, oldval, data, status) {
      let query;
      if (this.primaryKey) {
        let column = data.column;
        let row = data.row;

        if (status) {
          //如果修改成功那么显示新的值否则显示老的值
          row[column] = val;
        } else {
          row[column] = oldval;
        }

        query = _.pick(row, this.primaryKey);
        let findRow = _.find(this.filterRows, query);
        delete row[`${column}_format`];
        findRow[column] = row[column];
        // this.tableRows.splice(0,0)
        this.$refs.elementTable.doLayout();
      } else {
        this.$message.error("Table primary key not found.");
      }
    },
    //计算汇总数据
    summaryStatistics,
    //导出数据
    exportData,
    //基于数据出发plugins重新过滤数据 format filter filter.range
    initTablePlugins,
    dataTriggerPlugins,
    columnsTriggerPlugins,
    showColumnsTrigger,
    showFormulaColumnsEval,
    filtering,
    collectionGroup,
    ...cellIterator, //单元格数据补全
  },
  watch: {
    channelType: function () {
      this.bPageShow = false;
      this.$nextTick(() => {
        this.bPageShow = true;
      });
    },
    parentCurrentPage: function () {
      // setInterval(() => {
      //   this.MyCurrentPage = this.parentCurrentPage;
      // }, 600);
      this.MyCurrentPage = this.parentCurrentPage;
    },
    tableShowColumns: _.debounce(function () {
      setTimeout(() => {
        // this.rows.splice(0,0)
        //对全部计算列进行计算
        this.showFormulaColumnsEval("columns");
        //只触发渲染数据提高性能
        this.tableRows.splice(0, 0);
      }, 0);
    }, 100),

    options: {
      handler: function (options) {
        //配合 client mixins 使用
        let maxHeight = this.options?.maxHeight ?? -185;
        let rowHeight = this.options?.rowHeight ?? 40;
        if (maxHeight < 0) {
          maxHeight = this.clientHeight + maxHeight;
        }
        ///////////////////////

        let op = _.assign(
          {
            //表格默认参数
            border: true,
            stripe: true,
            size: "medium",
            rowKey: (row) => {
              if (!row.__pk__) {
                row.__pk__ = null;
                if (this.primaryKey) {
                  row.__pk__ = getUniqueRandomNumber(1, 60000);
                } else {
                  row.__pk__ = getUniqueRandomNumber(1, 60000);
                }
              }

              return row.__pk__;
            },
            rowId: "__pk__",
            headerCellStyle: ({ row, column, rowIndex, columnIndex }) => {
              //对于表头单元格设置背景色
              const c = this.getColumnCategory(column.property);
              if (_.includes(c, "自定义数据")) {
                return { background: "#edfff0" };
              }

              if (_.includes(c, "第三方数据")) {
                return { background: "#efefef" };
              }
            },
          },
          fastCopy(options),
          { maxHeight },
          { rowHeight }
        );

        this.tableOptions = op;

        //如果设置了默认排序使用自己写的数据排序
        if (op.defaultSort) {
          this.sortChange(op.defaultSort);
        }

        //tablePlugins 使用计算属性会有很严重的性能问题
        // this.tablePlugins = this.initTablePlugins();
      },
      immediate: true,
      deep: true,
    },
    columns: {
      handler: function () {
        this.columnsTriggerPlugins();
        this.initTablePlugins();
        this.showColumnsTrigger();
      },
      immediate: true,
      deep: true,
    },
    // tableColumnProps: {
    //   handler: function (data) {
    //     this.initTablePlugins();
    //     this.showColumnsTrigger()
    //   },
    //   deep: true
    // },
    data: {
      handler: function (data) {
        this.dataRows = fastCopy(data);
        // this.dataRows = data;
      },
      immediate: true,
      //deep: true
    },
    dataRows: {
      handler: function (data) {
        this.rows = data;
        this.initTablePlugins();
      },
      // immediate: true,
      //deep: true
    },

    rows: function () {
      this.dataTriggerPlugins();
      this.filterRows = fastCopy(this.rows);
      // this.total = this.filterRows.length;

      //每次重载数据重置到第一页 防止错误发生

      this.filtering(this.query);
    },

    // rows : _.debounce(function() {
    //   this.dataTriggerPlugins();
    //   this.filterRows = fastCopy(this.rows);
    //   this.total = this.filterRows.length;

    //   //每次重载数据重置到第一页 防止错误发生
    //   this.currentPage = 1;

    //   this.filtering(this.query);
    // }, 10),
    plugins: {
      handler: function (data) {
        //tablePlugins 使用计算属性会有很严重的性能问题
        this.initTablePlugins();
      },
      immediate: true,
      deep: true,
    },
    query: {
      handler: function (q, oq) {
        this.$emit("query", q);
        this.filtering(q);
      },
      deep: true,
    },
    filterRows: {
      handler: function () {
        if (this.tablePlugins.summary) {
          this.summaryStatistics();
        }
      },
      immediate: true,
    },
    tableRows: {
      handler: function (rows) {
        ////////////////////////////////////////////////////////////////

        this.$nextTick(() => {
          if (_.size(this.checkboxChecked)) {
            this.setCheckboxChecked(this.checkboxChecked);
            // let rows = !this.plugins?.useTree
            //   ? this.$refs.elementTable.getUTreeData()
            //   : this.rows;

            // let checkboxChecked = this.inRowsByPk(this.checkboxChecked, rows)

            // if (checkboxChecked) {
            //   if (!this.plugins?.useTree) {
            //     //如果不是柱状结构表
            //     checkboxChecked.forEach( row => {
            //       //备注此项是element ui写法-----****不可删除
            //       // this.$refs.elementTable.toggleRowSelection(row, true);
            //       this.$refs.elementTable.toggleRowSelection([{ row }]);
            //     });
            //   } else {
            //     //树结构且不带开关
            //     checkboxChecked.forEach( row => {
            //       this.$refs.elementTable.toggleRowSelection(row, true);
            //     });
            //   }
            // }
          }
        });
      },
      immediate: true,
    },

    checkboxChecked: function () {
      //每次勾选均出发事件
      this.$emit("checkbox-checked", this.checkboxChecked);
    },
    radioChecked: function () {
      //每次勾选均出发事件
      this.$emit("radio-checked", this.radioChecked);
    },
  },
};
</script>
