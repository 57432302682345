<template>
  <div class="platform-table-custom-columns">
    <el-dialog
      title="自定义显示列"
      :visible.sync="dialog"
      width="50%"
      :close-on-click-modal="false"
    >
      <div class="dailog-body">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="col-height">
              <el-input
                placeholder="输入关键字进行过滤"
                v-model="filterText"
                style="margin-bottom: 10px"
                clearable
              >
              </el-input>
              <div class="tree-height">
                <el-tree
                  :data="treeNode"
                  show-checkbox
                  default-expand-all
                  node-key="id"
                  ref="tree"
                  highlight-current
                  :props="defaultProps"
                  @check-change="treeNodeCheck"
                  :filter-node-method="filterNode"
                >
                </el-tree>
              </div></div
          ></el-col>
          <el-col :span="12">
            <div class="col-height" style="overflow: auto">
              <draggable
                :list="listNode"
                :disabled="!enabled"
                class="list-group"
                ghost-class="card-ghost"
                @start="dragging = true"
                @end="dragging = false"
              >
                <el-card shadow="hover" v-for="v in listNode" :key="v">
                  <span>{{ getPlatformTable().getColumnLabel(v) }}</span>
                  <span style="float: right">
                    <span @click="setTopNode(v)" class="btn"
                      ><i
                        class="mdi mdi-format-vertical-align-top"
                        style="padding: 3px"
                      ></i
                    ></span>
                    <span @click="setBottomNode(v)" class="btn"
                      ><i
                        class="mdi mdi-format-vertical-align-bottom"
                        style="padding: 3px"
                      ></i
                    ></span>
                    <span @click="removeNode(v)" class="btn"
                      ><i class="el-icon-close" style="padding: 3px"></i
                    ></span>
                  </span>
                </el-card>
              </draggable>
            </div>
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-form :inline="true" @submit.native.prevent>
          <el-form-item style="float: left">
            <el-radio-group v-model="propType">
              <el-radio-button label="user"
                ><i class="el-icon-user"></i> 私有</el-radio-button
              >
              <el-radio-button label="company"
                ><i class="el-icon-share"></i> 共享</el-radio-button
              >
            </el-radio-group>
            <router-link to="/originalityCreatRules/admin">
              <el-button type="warning"
                ><i class="el-icon-link"></i> 自定义字段</el-button
              >
            </router-link>
          </el-form-item>

          <el-form-item label="表头名称">
            <el-input v-model="propName" placeholder="默认表头"> </el-input>
          </el-form-item>
          <!--如果是新建 或 私有 或 所有者是当前用户 或 没有所有者 的情况下允许保存或新建-->
          <!-- <el-form-item v-if="!isUpdate || propType == 'user' || searchUserId == $user.id"> -->
          <el-form-item>
            <!--如果是共享 并且 不属于当前用户 并且 是保存的 就禁止操作-->
            <el-button
              :disabled="
                propType == 'company' && searchUserId != $user.id && isUpdate
              "
              type="primary"
              @click="save"
              >{{ isUpdate ? "保 存" : "新 建" }}</el-button
            >
          </el-form-item>
          <el-form-item>
            <el-button @click="dialog = false">取 消</el-button>
          </el-form-item>
        </el-form>
      </span>
    </el-dialog>

    <el-dropdown
      split-button
      @click="customMethod"
      @command="handleCommand"
      trigger="click"
      :type="custom.type == 'company' ? 'primary' : 'default'"
    >
      <el-tooltip
        :content="(custom.type == 'company' ? '共享 ' : '私有 ') + custom.name"
      >
        <span class="max-length"
          ><i class="fa fa-columns"></i> {{ this.custom.name }}</span
        >
      </el-tooltip>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          v-for="(v, k) in custom.headers.user"
          :key="v.name + '_user'"
          :command="{ data: v, type: 'user' }"
        >
          <span><i class="el-icon-user"></i> {{ v.name }}</span>
          <span
            style="float: right; padding-left: 10px"
            :title="
              (v.name == '默认表头' ? '重置' : '删除') + ' 私有 ' + v.name
            "
          >
            <i class="el-icon-delete" @click.stop="remove(v.name, 'user')"></i>
          </span>
        </el-dropdown-item>
        <el-dropdown-item
          v-for="(v, k) in custom.headers.company"
          :key="v.name + '_company'"
          :command="{ data: v, type: 'company' }"
          :divided="k == 0 ? true : false"
        >
          <span><i class="el-icon-share"></i> {{ v.name }}</span>
          <span
            v-if="v.userId == $user.id"
            style="float: right; padding-left: 10px"
            :title="'删除 共享 ' + v.name"
          >
            <i
              class="el-icon-delete"
              @click.stop="remove(v.name, 'company')"
            ></i>
          </span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import md5 from "crypto-js/md5";
import draggable from "vuedraggable";
import fastCopy from "fast-copy";
import { useSettingsStore } from "@/store/pinia";
export default {
  props: {
    columns: {},
    // value: {
    //   required: true,
    // },
    custom: {
      type: Object,
      default: function () {
        return {
          name: "默认表头",
          type: "user",
          columns: [],
          userId: null,
          headers: {
            user: [],
            company: [],
          },
        };
      },
    },
  },
  components: {
    draggable,
  },
  computed: {
    isUpdate() {
      //如果propName为空也认为是保存
      if (!this.propName) {
        return true;
      }

      let header = _.find(this.custom.headers[this.propType], {
        name: this.propName,
      });

      //如果找到表头配置那么认为是保存
      if (header) {
        //同时将得到的配置用户id 传给当前 searchUserId 用于判断是否是所属用户
        this.searchUserId = header.userId;
        return true;
      }

      return false;
    },
  },
  watch: {
    custom: {
      handler(custom) {
        this.propName = custom.name;
        this.propColumns = custom.columns;
        this.propType = custom.type;
        this.propUserId = custom.userId;
      },
      immediate: true,
      deep: true,
    },
    columns: {
      handler(columns) {
        this.treeData = this.treeDataMarker(columns);
        this.treeNode = this.treeNodeMarker(fastCopy(this.treeData));
      },
      immediate: true,
      deep: true,
    },

    listNode(n, o) {
      this.$nextTick(() => {
        // this.$refs.tree.setCheckedKeys([]);
        this.$refs.tree.setCheckedKeys(n);
      });
    },

    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  methods: {
    handleCommand(v) {
 
      let settingsStore = useSettingsStore();
      settingsStore.handleIsConfigReport(false);
      // this.$nextTick(() => {
      this.$set(this.getPlatformTable().custom, "columns", v.data.columns);
      this.$set(this.getPlatformTable().custom, "name", v.data.name);
      this.$set(this.getPlatformTable().custom, "userId", v.data.userId);
      this.$set(this.getPlatformTable().custom, "type", v.type);
      // console.log(v, this.isUpdate, "handleCommand");
      // })
    },
    treeDataMarker(columns) {
      let treeData = [];

      for (let c in columns) {
        let category = columns[c].category ?? "";
        let categoryNode = category.split(".");
        let categoryNodeId = category
          ? `$__${md5(category)}`
          : "$__00000000000000000000000000000000";

        _.forEach(categoryNode, (v, k) => {
          if (v) {
            let thisPath = _.join(_.take(categoryNode, k + 1), ".");
            let thisId = `$__${md5(thisPath)}`;

            //如果已经包含该文件夹那么不再添加到文件夹节点中
            let parentPath = _.join(_.take(categoryNode, k), ".");
            let parentId =
              k > 0
                ? `$__${md5(parentPath)}`
                : "$__00000000000000000000000000000000";

            if (!_.find(treeData, { id: thisId })) {
              treeData.push({
                id: thisId,
                label: v,
                parentId: parentId,
              });
            }
          }
        });

        //所有fixed的列不出现在勾选树中
        if (
          _.isBoolean(columns[c].fixed) &&
          this.getPlatformTable().group.columns.includes(c)
        ) {
          treeData.push({
            id: c,
            label: this.getLabel(c),
            parentId: categoryNodeId,
          });
        } else if (!_.isBoolean(columns[c].fixed)) {
          treeData.push({
            id: c,
            label: this.getLabel(c),
            parentId: categoryNodeId,
          });
        }
      }

      return treeData;
    },
    treeNodeMarker(nodes, parentId = "$__00000000000000000000000000000000") {
      let subNodes = _.filter(nodes, { parentId: String(parentId) });

      if (!_.size(subNodes)) {
        return nodes;
      }

      let tn = _.map(subNodes, (node) => {
        let childNodes = _.filter(nodes, { parentId: String(node.id) });
        if (_.size(childNodes)) {
          node.children = this.treeNodeMarker(nodes, node.id);
          return node;
        } else {
          return node;
        }
      });

      return tn;
    },
    filterNode(value, data) {
      if (!value) return true;

      let search = new RegExp(_.escapeRegExp(value), "i");
      return data.label.search(search) != -1;
    },
    getPlatformTable() {
      return this.$parent.$parent.$parent;
    },
    getLabel(column) {
      if (column.indexOf("$__") != -1 || column == null) {
        return column;
      } else {
        try {
          return this.getPlatformTable().getColumnName(column);
        } catch {
          return column;
        }
      }
    },
    remove(name, type) {
      this.$confirm(
        `是否删除 ${type == "company" ? "共享" : "私有"} ${name} ？`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.$emit("remove", name, type);
          this.dialog = false;
          this.$message({
            type: "success",
            message: "删除成功",
          });
        })
        .catch(() => {});
    },
    save() {
      // this.$emit("input", this.listNode);
      // this.$emit("update:name", this.name);
      this.$emit("save", this.propName, this.listNode, this.propType);
      this.dialog = false;
    },
    customMethod() {
      //如果自定义列不存在那么根据节点树及不隐藏的列构建数据
      if (!this.propColumns.length) {
        let tempColumns = [];
        for (let i in this.treeData) {
          //如果该字段是隐藏或者是虚拟文件夹节点那么不勾选
          if (
            this.treeData[i].id.indexOf("$__") !== 0 &&
            !this.columns[this.treeData[i].id]?.hidden
          ) {
            tempColumns.push(this.treeData[i].id);
          }
        }
        this.listNode = tempColumns;
      } else {
        //否则根据自定义列显示排序
        //拼凑固fixed列
        let fixedCoulmns = [];
        let tablePlugins = this.getPlatformTable().tablePlugins;
        if (tablePlugins.fixed) {
          _.forEach(tablePlugins.fixed, (v, k) => {
            //把不是 group 的 fixed 字段挑选出来
            if (!this.getPlatformTable().group.columns.includes(k)) {
              fixedCoulmns.push(k);
            }
          });
        }

        //去除固定列，用于清除以前不是fixed列，现在业务逻辑改变，变成fixed列的数据
        this.listNode = _.difference(this.propColumns, fixedCoulmns);
      }

      _.pullAll(
        this.listNode,
        _.difference(this.listNode, this.getPlatformTable().tableColumnFields)
      );

      this.dialog = true;
    },
    treeNodeCheck(node, checked) {
      //如果不是父级虚拟节点才勾选到列表中
      if (node.id.indexOf("$__") !== 0) {
        //如果是选中
        if (checked) {
          if (!_.includes(this.listNode, node.id)) {
            //改为从顶部压入顺序 最新勾选放最前面
            this.listNode.unshift(node.id);
          }
        } else {
          //未选中
          if (_.includes(this.listNode, node.id)) {
            this.$delete(this.listNode, _.indexOf(this.listNode, node.id));
          }
        }
      }

      //过去采用数据比较模式
      // let cks = [];
      // _.forEach(checkedData.checkedKeys, (v) => {
      //   if (v.indexOf("$__") !== 0) {
      //     cks.push(v);
      //   }
      // });

      // let ln = [];
      // _.forEach(this.listNode, (v, k) => {
      //   if (checkedData.checkedKeys.includes(v)) {
      //     ln.push(v);
      //   }
      // });

      // this.listNode = _.uniq(_.concat(ln, cks));
    },

    removeNode(column) {
      if (_.includes(this.listNode, column)) {
        this.$delete(this.listNode, _.indexOf(this.listNode, column));
      }
    },
    setTopNode(column) {
      this.removeNode(column);
      this.$nextTick(() => {
        this.listNode.unshift(column);
      });
    },
    setBottomNode(column) {
      this.removeNode(column);
      this.$nextTick(() => {
        this.listNode.push(column);
      });
    },
  },
  data() {
    return {
      propName: "",
      propColumns: "",
      propType: "",
      propUserId: "",
      searchUserId: "", //用于存储输入框修改名称，实时查询出来表头配置的所属 用户id
      dialog: false,
      enabled: true,
      filterText: "",
      dragging: false,
      treeNode: [],
      treeData: [],
      listNode: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
    };
  },
};
</script>

<style lang="scss">
@import "@/styles/var.scss";
.platform-table-custom-columns {
  .max-length {
    max-width: 90px;
    white-space: nowrap;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }

  .el-dialog {
    .el-dialog__body {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
  .dailog-body {
    .col-height {
      height: 45vh;
    }
    .tree-height {
      height: calc(100% - 46px);
      overflow: auto;
    }
    .card-ghost {
      opacity: 0.8;
      color: #fff;
      background: $--color-primary;
    }
    .el-card {
      cursor: move;
      margin: 0 5px 5px 5px;
      .el-card__body {
        line-height: initial;
        padding: 10px;
      }
      .btn {
        cursor: pointer;
      }
    }
  }
}
</style>
