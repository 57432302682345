<template>
  <div>
    <el-dropdown
      split-button
      @click="savePrompt"
      @command="handleCommand"
      trigger="click"
    >
      <span class="max-length"
        ><i class="el-icon-s-flag"></i> {{ component_config.name }}</span
      >
      <el-dropdown-menu slot="dropdown" style="max-height: 500px;overflow-y: auto;">
        <el-dropdown-item
          v-for="config in component_config.configs"
          :key="config.name"
          :command="config.name"
        >
          <span>{{ config.name }}</span>
          <span style="float: right; padding-left: 10px">
            <el-tooltip
              effect="dark"
              :content="
                (config.name == '默认配置' ? '重置' : '删除') +
                ' ' +
                config.name
              "
            >
              <i
                class="el-icon-delete"
                @click.stop="removeConfirm(config.name)"
              ></i>
            </el-tooltip>
          </span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>
import { useSettingsStore } from "@/store/pinia";
export default {
  props: {
    value: {
      required: true,
    },
    disabled: {
      required: false,
    },
  },
  data() {
    return {
      component_config: {},
    };
  },
  methods: {
    handleCommand(v) {
      let settingsStore = useSettingsStore();
      settingsStore.handleIsConfigReport(true);

      let oldVal = this.component_config.value;
      this.component_config.name = v;

      this.$nextTick(() => {
        let newVal = this.component_config.value;
        this.$emit("change", newVal, oldVal);
      });
    },
    savePrompt() {
      this.$prompt(
        "请输入需要保存的配置名称，如配置名称不存在则新建",
        "保存配置",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputValue: this.component_config.name,
          // inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
          // inputErrorMessage: '邮箱格式不正确'
        }
      )
        .then(({ value }) => {
          //传入完成函数，参数为是否修改成功 true 陈功 false 不成功
          this.$emit("save", value);
        })
        .catch(() => {});
    },

    removeConfirm(name) {
      let type = name == "默认配置" ? "重置" : "删除";

      this.$confirm(`请确认是否${type} ${name}`, `${type}配置`, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        // inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
        // inputErrorMessage: '邮箱格式不正确'
      })
        .then(() => {
          //传入完成函数，参数为是否修改成功 true 陈功 false 不成功
          this.$emit("remove", name);
        })
        .catch(() => {});
    },
  },
  watch: {
    "component_config.name"(val) {
      // this.component_config.name = val
      this.$emit("input", this.component_config);
    },
    value: {
      handler: function (value) {
        this.component_config = value;
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
