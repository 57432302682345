<template>
<div class="parent-formula-input">
  <div class="formula-input" @keydown="keydownEvent" @keyup="keyupEvent" @click="clickEvent" @blur="blurEvent" contenteditable="true" spellcheck = "false" v-html="inputValue"></div>
</div>
</template>

<script>
//解决空数据光标不显示的问题
// https://stackoverflow.com/questions/5750559/contenteditable-div-has-a-default-border-in-ff-chrome-how-to-hide-it

//解决插入内容问题
//https://stackoverflow.com/questions/6690752/insert-html-at-caret-in-a-contenteditable-div
import VanillaCaret from 'vanilla-caret-js'
import _ from 'lodash'
import {convert} from "html-to-text"

export default {
  name: 'FormulaInput',
  props: {
    formula: {
      required: true
    },
    options: [],
  },
  data() {
    return {
      caretPos: null,
      inputValue: '',
      inputText: '',
      inputOptions: []
    }
  },
  watch: {
    options: {
      handler: function (v) {
        this.inputOptions = _.orderBy(v, [({ value }) => value.length, 'value'], ['desc']);
      },
      immediate: true,
    },
    formula: {
      handler: function (v) {
        this.inputText = this.formulaInTransform(v)
        let inputValue = this.inputText
        _.forEach(this.inputOptions, o => {
          let v = this.formulaInTransform(o.value)
          //如果是公式那么将公式加上括号后替换
          if(v.match(/[\+\-\*\/]/)) {
            v = `(${v})`
          }

          inputValue = inputValue.replace(new RegExp(_.escapeRegExp(v), "g"), `<span class="field-keyword" v="${o.value}">${o.label}</span>`)
        })

        this.inputValue = inputValue

        this.$nextTick(() => {
          //如果没有光标位置，那么使用输入框已有内容长度作为光标插入位置
          if(inputValue || this.caretPos == null) {
            this.caretPositionSet(convert(inputValue).length)
          }
        })
      },
      immediate: true,
    },
    inputValue (v) {
      // console.log(v)
    },
    inputText (v) {
      // console.log(v)
    },

    caretPos(v) {
      // console.log(v)
    },
  },
  mounted() {
  },
  methods: {
    formulaInTransform (f) {
      f = f === undefined ? '' : f;
      return  f.replace(/(\s+)/g, '').replace(/([a-zA-Z_]{1,}[a-zA-Z0-9_]*)/g, '[$1]')
    },
    getFormula () {
      var target = this.getFormulaInputEl()
      return convert(_.replace(target.innerHTML, new RegExp(`<span.+?v="([^"]+)".+?</span>`,"g"), "($1)")).replace(/\(([a-zA-Z_]{1,}[a-zA-Z0-9_]*)\)/g, '$1').replace(/\s+/, '')
    },
    //离开焦点通同步数据
    blurEvent(event) {
    },
    clickEvent(event) {
        this.caretPositionSave()
    },

    getSelectionRange() {
      return window.getSelection().getRangeAt(0)
    },
    getFormulaInputEl() {
      return this.$el.querySelector('.formula-input');
    },
    keyupEvent(event) {
      this.caretPositionSave()
    },
    keydownEvent(event) {

      let keys = [
        'Backspace','Delete','ArrowLeft','ArrowRight','ArrowUp','ArrowDown','Home','End'
      ]
      //如果不在允许输入内容的范围内，那么禁止输入
      if(!_.includes('.0123456789+-*/() ', event.key) && !_.includes(keys, event.key)) {
        event.preventDefault();
      }

      /////////////////////

      let parentNode = getSelection().anchorNode.parentElement;
      let className = parentNode.className;

      let obj = this.getFormulaInputEl()
      let position = 0;
      for (let j = 0; j < obj.childNodes.length; j++) {
        if (obj.childNodes[j] === this.getSelectionRange().endContainer.parentNode) {
          position = j + 1;
        }
      }

      // console.log(obj.childNodes, position, obj.childNodes[position].className)

      if(className == 'field-keyword') {

        //如果是移动光标那么自由移动
        if(_.includes(['ArrowLeft','ArrowRight','ArrowUp','ArrowDown','Home','End'], event.key)) {
          return
        }

        //如果是删除那么删除整个tag
        if(event.key == 'Backspace') {
          parentNode.remove()
          event.preventDefault();
        }

        //如果是删除那么删除
        if(event.key == 'Delete') {
          //如果删除时，光标不是紧挨着标签尾部那么删除整个标签
          if (getSelection().anchorOffset !== getSelection().anchorNode.length) {
            parentNode.remove()
            event.preventDefault();
          } else  if(obj.childNodes[position]?.nodeName != '#text') { //使用原有删除事件删除下一个标签
            // console.log(obj.childNodes[position], parentNode)
            obj.childNodes[position]?.remove()
            event.preventDefault();
          }
        }

        if(_.includes('.0123456789+-*/() ', event.key)) {
          //如果是在标签尾部输入字符串，那么插入数据
          if(getSelection().anchorOffset === getSelection().anchorNode.length) {
            obj.insertBefore(this.getSelectionRange().createContextualFragment(event.key), obj.childNodes[position])
            //设置输入后的贯标位置
            this.caretPositionSet(this.caretPos + 1)
            //禁止原有键盘事件触发
            event.preventDefault();
          } else if(getSelection().anchorOffset === 0){ //如果是在输入框头部输入那么不受影响可以输入
            // console.log(getSelection(), '11')
            this.insertData(event.key)
            event.preventDefault();
          } else {
            event.preventDefault();
          }
        }
      } else {
        // 用于Delete键盘事件，整块删除字段
        if(event.key == 'Delete') {
          if(getSelection().anchorNode.length) { //大多数字符串符号后紧贴着标签情况删除标签
            // console.log(getSelection(), 'length')
            if(getSelection().anchorOffset === getSelection().anchorNode.length) {
              getSelection().anchorNode?.nextSibling?.remove()
              event.preventDefault();
            }
          } else if (getSelection().anchorNode.firstChild) { //对于标签紧贴着输入框最前面，特殊情况删除标签
            // console.log(getSelection(), 'firstChild')
            if(getSelection().anchorNode.firstChild.className == 'field-keyword') { //如果节点是标签那么删除
              getSelection().anchorNode?.firstChild?.remove()
              event.preventDefault();
            }
          }
        }
      }
    },
    caretPositionSet(pos = null) {
      let caret = new VanillaCaret(this.getFormulaInputEl()); // Initialize
      if(pos === null) {
        caret.setPos(this.caretPos)
      } else { //如果是指定位置那么移动到置定位并保存
        caret.setPos(pos)
        this.caretPositionSave()
      }
    },
    caretPositionSave() {
      let caret = new VanillaCaret(this.getFormulaInputEl()); // Initialize
      this.caretPos = caret.getPos()
    },

    insertData (value) {
      let o = _.find(this.options, {"value": value});
      let i;
      if(_.size(o)) {
        i = `<span class="field-keyword" v="${o.value}">${o.label}</span>`
      } else {
        i = `${value}`
      }

      //将光标定位
      this.caretPositionSet();

      //插入数据
      this.pasteHtmlAtCaret(i)
    },
    pasteHtmlAtCaret(html) {
      var range;
      range = getSelection().getRangeAt(0);
      var frag = range.createContextualFragment(html)

      let parentNode = getSelection().anchorNode.parentElement;
      let className = parentNode.className;

      let isInsert = false
      if(className == 'parent-formula-input') {
        this.getFormulaInputEl().innerHTML = html + this.getFormulaInputEl().innerHTML
        // console.log('头部插入数据')
        isInsert = true
      } else if(className == 'formula-input') {
        range.insertNode(frag);
        range.collapse(true);
        // console.log('正常插入数据，不是在字段内')
        isInsert = true
      } else if(className == 'field-keyword') {
        if(getSelection().anchorOffset == 0){
          this.getFormulaInputEl().innerHTML = html + this.getFormulaInputEl().innerHTML
          // console.log('头部插入数据，并且是字段在第一个')
          isInsert = true
        } else if(getSelection().anchorOffset == getSelection().anchorNode.length){
          let obj = this.getFormulaInputEl()
          let position = 0;
          for (let j = 0; j < obj.childNodes.length; j++) {
            if (obj.childNodes[j] === this.getSelectionRange().endContainer.parentNode) {
              position = j + 1;
            }
          }

          obj.insertBefore(frag, obj.childNodes[position])
          // console.log('紧挨着字段结束插入')
          isInsert = true
        }
      }

      if(isInsert) { //如果成功插入数据那么将光标移动到插入内容后
        this.$nextTick(() => {
          this.caretPositionSet(this.caretPos + convert(html).length)
        })
      }
    }
  }
}
</script>

<style lang="scss">
// @import "@/styles/var.scss";
.formula-input {
  border: 1px solid #dcdfe6;
  padding: 4px;
  border-radius: 4px;
  background: #fff;
  min-height: 34px;
  outline: none!important;

  .field-keyword {
    color: #6790da;
  }
  // display:inline-block;
  // padding: 0 2px;
}
</style>
