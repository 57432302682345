//生成 API 请求路径类
class PathGenerate {
    path(moduleName, method) {
        //如果模块名存在 .(点) 说明调用的是 module.controller 中的方法 注意！不是 action 是方法
        //否则直接调用model模块
        let apipath = '';
        if(moduleName.indexOf('.') != -1) {
            apipath = '/'+ moduleName.replace('.', '/')+'/PlatformApi/method/'+method;
        } else {
            apipath = '/platform/api/modelapi/'+moduleName+'/'+method;
        }
        return apipath;
    }
}

export default PathGenerate;