
import ComponentFilterscColumns from './columns.vue'

import {httpUseCustom} from "@/api/common";

export default {
  components:{
    'component-filters-columns': ComponentFilterscColumns
  },
  watch: {
    "filters.condition" : {
      handler: function (filtersCondition, oldFiltersCondition) {
        if(!_.isEqual(filtersCondition, oldFiltersCondition)) {
          // if(!customColumns.length) {
          //   this.custom.columns = this.tableDefalutShowColumns
          // }

          this.query.filters = filtersCondition

          // this.showColumnsTrigger()
          // this.customColumnsSave()
        }
      },
      // immediate: true,
      deep: true,
    },
    "filters.name" : {
      handler (name) {
        // 暂不启用,保存最后一次使用的过滤条件当做下次进入的筛选条件
        if(this.filters.items) {
          let save = true //默认保存每次重新选择的筛选条件
          _.forEach(this.filters.items, filter => {

            //如果选择的筛选条件本身就是默认筛选条件 那么不保存
            if(filter.name == name && filter.default) {
              save = false
            }

            if(filter.name == name) {
              filter.default = true
            } else {
              filter.default = false
            }
          })
          // 保存筛选条件
          if(save) {
            httpUseCustom({key:'table:filter:' + this.id, value:this.filters.items,type:'update'})
            .then((status) => {
            })
          }
        }
      },
    }
  },
  data() {
    return {
      filters: {
        dialog: false,
        ranges: {},
        name: undefined, //当前过滤组合名称
        condition: [{column: undefined,condition: undefined,value: undefined}], //当前过滤组合 [{column:"field",condition:">=|<=|=|in",value:"1|[]"},{...},{...}]
        filterRule:"and",
        items: [] //用于保存所有头信息，包含多个filters.condition [{name:undefined, condition: [{column: undefined,condition: undefined,value: undefined}]}]
      }
    }
  },
  mounted () {
    // console.log(this.filters)
    this.filtersConditionLoad()
  },
  methods: {
    filtersReset() {
      this.filters.name = undefined
      this.filters.condition = [{column: undefined,condition: undefined,value: undefined}]
      this.filters.filterRule='and'
    },
    filtersConditionLoad () {
      //如果范围筛选开启，并且表格ID存在，同时范围筛选数据未加载的情况下才加载数据
      if (this.tablePlugins.filters && this.id) {
        //如果存在自定义数据用自定义数据
        // this.$api('platform.user').custom('table:filter:' + this.id)
        httpUseCustom({key:'table:filter:' + this.id,value:[]}).then((filterData) => {
          if(_.isArray(filterData) && filterData.length > 0) {
            //设置表头
            this.$set(this.filters, 'items', filterData)

            let findName = this.filters.name
            _.forEach(filterData, filter => {
              if(filter.default) {
                findName = filter.name
              }
            })

            //查找当前表头数据
            let findItem = {'name': findName}
            let i = _.findIndex(filterData, findItem);

            //如果当前表头在表头组中，并且有配置数据，那么使用配置数据
            if(filterData[i]?.condition && filterData[i].condition.length > 0) {
              this.$set(this.filters, 'condition', filterData[i].condition)
              this.$set(this.filters, 'name', filterData[i].name);
              this.$set(this.filters, 'filterRule', filterData[i]?.filterRule || "and");
              let sessionStorageVar={
                condition:filterData[i].condition,
                filterRule:filterData[i].filterRule || "and"
              }
              sessionStorage.setItem(this.id.split('admin:')[1],JSON.stringify(sessionStorageVar))
            }
          }
        });
      }
    },
    filtersConditionSave (name, condition,filterRule) {
      if (this.tablePlugins.filters && this.id) {
        //如果没填写表头那么认为是修改默认过滤
        this.filters.name = name
        this.filters.filterRule = filterRule
        this.filters.condition = condition

        //根据传入的名字和表头信息组成 item 以便于更新或新增
        let item = {'name': this.filters.name, 'condition': this.filters.condition,'filterRule':this.filters.filterRule}

        let findItem = {'name': this.filters.name}

        //如果查找到说明修改已有表头
        let i = _.findIndex(this.filters.items, findItem);
        if(i >= 0) {
          this.filters.items[i].condition = item.condition
          this.filters.items[i].filterRule = filterRule
        } else { //没有查找到则新增表头
          this.filters.items.push(item)
        }

        // 保存表头
        httpUseCustom({key:'table:filter:' + this.id, value:this.filters.items,type:'update'})
        .then((status) => {
          this.$emit("handleChangeFilter",condition,filterRule);
        })
      }
    },
    handleSelectFilter(condition,filterRule){
      this.$emit("handleChangeFilter",condition,filterRule);
    },
    //删除筛选器
    filtersConditionRemove (name) {

      if (this.tablePlugins.filters && this.id) {

        //根据当前表头名称删除筛选器
        let findItem = {'name': name}
        _.remove(this.filters.items, findItem)

        // //删除后使用空数据构造
        this.filtersReset()

        //保存删除后的表头数据
        httpUseCustom({key:'table:filter:' + this.id, value:this.filters.items})
        .then((status) => {
        })
      }
    },
  }
}
