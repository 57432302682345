import fastCopy from "fast-copy";

export default function () {
  this.tableColumnFields = [];
  this.tableDefalutShowColumns = [];

  //初始化字段参数默认值
  let columnProps = {
    minWidth: "110",
    sortable: true,
    showOverflowTooltip: false,
  };

  let cs = fastCopy(this.columns);
  _.forEach(cs, (c, i) => {
    let {column} = c
    if(column=='companyName' ||column == 'username' ||  column=='advertiser'){
      columnProps.sortable = false
    }else{
      columnProps.sortable = true
    }
    
    let n,
      p = {};
    if (typeof c == "object") {
      n = c.column;
      p = _.assign({}, columnProps, c);
    } else {
      n = c;
      p = _.assign({ column: n }, columnProps, {});
    }

    //兼容老的写法
    p.label =
      p.label ??
      (this.tableOptions?.headings && this.tableOptions?.headings[n]
        ? this.tableOptions?.headings[n]
        : undefined);

    //实际上最终使用的都是 renderHeader 构建
    if (p.label) {
      //如果标签存在那么就使用标签
      p.renderHeader = this.renderHeaderWrapper(
        ((column, i) => {
          return function (h, { column, $index }) {
            return <span>{p.label}</span>;
          };
        })(n, i),
        p
      );
    } else {
      //如果label不存在才使用 renderHeader
      if (p.renderHeader) {
        //如果 renderHeader 存在
        p.renderHeader = this.renderHeaderWrapper(p.renderHeader);
      } else {
        p.renderHeader = this.renderHeaderWrapper(
          this.defaultRenderHeader(n, i)
        );
      }
    }

    if (!p?.hidden) {
      this.tableDefalutShowColumns.push(n);
    }

    this.tableColumnFields.push(n);
    this.$set(this.tableColumnProps, n, p);
    this.$set(this.tableColumnNames, n, this.getColumnName(n));
  });
  // this.showColumnsTrigger()
}
