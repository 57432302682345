export default {
  columns: {
    type: Array,
    required: true,
  },
  data: {
    type: Array,
    required: true,
  },
  parentCurrentPage: {
    type: Number,
    default: 1,
  },
  parentPageSize: {
    type: Number,
    default: 100,
  },
  newTotal: {
    type: Number,
    default: 0,
  },
  channelType: {
    type: String,
  },
  parentAllParam: {
    type: Object,
    required: false,
    default: function () {
      return {};
    },
  },
  is_authorized:{
    type: Boolean,
    required: false,
    default: false,
  },
  isGroup: {
    type: Boolean,
    required: false,
    default: true,
  },
  rowKey: {
    type: String,
    default: "",
    required: false,
  },
  madCurrentPage: {
    type: [String,Number],
    default: "1",

  },
  madPageSize: {
    type: [String,Number],
    default: "20",

  },
  groupByField: {
    type: String,
    default: "",
    required: false,
  },
  thirdDataTotalField: {
    type: Object,
    default: "",
    required: false,
    default: function () {
      return {};
    },
  },
  totalField: {
    type: Object,
    required: false,
    default: function () {
      return {
        channel_common_spend: 0,
        channel_common_impressions: 0,
        channel_common_clicks: 0,
        customized_formula_system_1211: 0,
        channel_common_roas: 0,
        customized_formula_system_1209: 0,
        mmp_appsflyer_cohort_users: 0,
        channel_common_installs: 0,
        customized_formula_system_1197: 0,
        mmp_appsflyer_cohort_day1Retention: 0,
        mmp_appsflyer_cohort_day3Retention: 0,
        mmp_appsflyer_cohort_day7Retention: 0,
        customized_formula_system_1204: 0,
        customized_formula_system_1205: 0,
        customized_formula_system_1304: 0,
        customized_formula_system_1206: 0,
        customized_formula_system_1207: 0,
        customized_formula_system_1332: 0,
        customized_formula_system_1335: 0,
        customized_formula_system_1336: 0,
        customized_formula_system_1339: 0,
        customized_formula_system_1342: 0,
        customized_formula_system_1343: 0,
        mmp_appsflyer_cohort_day30PurchaseUniqueCounts: 0,
        mmp_appsflyer_cohort_day30PurchaseRate: 0,
        mmp_appsflyer_cohort_day0PurchaseRate: 0,
        mmp_appsflyer_cohort_day3PurchaseRate: 0,
        mmp_appsflyer_cohort_day7PurchaseRate: 0,
        channel_common_balance: 0,
        channel_common_totalSpend: 0,
        channel_common_totalBudget: 0,
        channel_common_dailyBudget: 0,
        channel_common_bidAmount: 0,
      };
    },
  },
  id: {
    type: String,
    required: false,
    default: function () {
      // return Math.random().toString(36).substr(2, 4);
      //默认使用hash路径作为ID，强烈建议单独设置ID
      return "";
      // return window.location.hash
    },
  },
  options: {
    type: Object,
    required: false,
    default: function () {
      return {};
    },
  },
  plugins: {
    type: Object,
    default: function () {
      return {};
    },
  },
};
