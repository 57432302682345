<template>
  <div :class="classObj" class="app-wrapper">
    <sidebar class="sidebar-container" />
    <div :class="{ hasTagsView: needTagsView }" class="main-container">
      <div :class="{ 'fixed-header': fixedHeader }">
        <navbar />
      </div>
      <app-main />
    </div>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { Sidebar, Navbar, AppMain } from "./components";
import { useSettingsStore, useAppStore } from "@/store/pinia";
export default {
  name: "Layout",
  components: {
    Sidebar,
    Navbar,
    AppMain,
  },

  computed: {
    ...mapState(useSettingsStore, {
      showSettings: (state) => state.showSettings,
      needTagsView: (state) => state.tagsView,
      fixedHeader: (state) => state.fixedHeader,
    }),
    ...mapState(useAppStore, {
      sidebar: (state) => state.sidebar,
      device: (state) => state.device,
    }),
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === "mobile",
      };
    },
  },
  methods: {
    handleClickOutside() {
      let appStore = useAppStore()
      appStore.handleCloseSidebar(false)

    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.scss";

.app-wrapper {
  @include clearfix;
  position: relative;
  height: 100%;
  width: 100%;
  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}

.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  width: calc(100% - #{$sideBarWidth});
  transition: width 0.28s;

}

.hideSidebar .fixed-header {
  width: 100%;
}

.mobile .fixed-header {
  width: 100%;
}
</style>
