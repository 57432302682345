<template>
  <div class="navbar">
    <hamburger
      id="hamburger-container"
      :is-active="sidebar.opened"
      class="hamburger-container"
      @toggleClick="toggleSideBar"
    />
    <breadcrumb id="breadcrumb-container" class="breadcrumb-container" />
    <div class="right-menu">
      <template v-if="company_en == 'madhouse'">
        <el-tooltip class="item" content=" 联系客服" placement="top">
          <a class="custom-icon" @click="handleToggleDialog">
            <img class="openAccount" src="../../../assets//img/seversTwo.png" />
          </a>
        </el-tooltip>

        <el-tooltip content="开户充值" placement="top">
          <a
            class="custom-icon"
            href="https://bf.show/yf_register.php"
            target="_blank"
          >
            <img
             v-if="inmad || user_type == 1"
           
              class="openAccount"
              src="../../../assets//img/sea-money.png"
            />
            <img
             v-else
              class="openAccount"
              src="../../../assets//img/inmad-money.png"
            />
            
          </a>
        </el-tooltip>
      </template>

      <el-tooltip class="item" content="帮助中心" placement="top">
        <a
          v-if="user_type == 1"
          class="doc-area"
          href="https://bluefocus.feishu.cn/docx/IoEKdcSvloTgn8x5dI8cwjNVn36"
          target="_blank"
        >
          <i class="fa fa-question-circle fa-lg"></i>
        </a>

        <a
          v-else
          class="doc-area"
          href="https://bluefocus.feishu.cn/docx/NJc3dbJzbo1EdJxd7Nyc715pnke"
          target="_blank"
        >
          <i class="fa fa-question-circle fa-lg" :class="{inmad:inmad}"></i>
        </a>
      </el-tooltip>

      <el-dropdown
        class="avatar-container right-menu-item hover-effect"
        trigger="click"
      >
        <div class="avatar-wrapper">
          <span class="name">{{ user.username }}</span>
          <i class="el-icon-caret-bottom" />
        </div>

        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="logout">
            <span style="display: block">退出登陆</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-dialog
      custom-class="mad-severs-dialog"
      :visible.sync="codeFlag"
      width="320px"
      :modal="false"
      :show-close="false"
    >
      <div class="mad-main">
        <div class="left-area">
          <img class="phone-icon" src="../../../assets//img/phone-icon.png" />
        </div>
        <div class="right-area">
          <img
            class="servers-code"
            src="../../../assets//img/serversThree.png"
          />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Hamburger from "@src/components/Hamburger";
import Breadcrumb from "@src/components/Breadcrumb";
import { mapState } from "pinia";
import { useAppStore, useUserStore } from "@/store/pinia";
import { removeToken } from "@src/utils/auth";
export default {
  data() {
    return {
      user_type: this.$user.user_type,
      inmad: this.$user.inmad,
      company_en: this.$user.company_en,
      codeFlag: false,
    };
  },
  components: {
    Hamburger,
    Breadcrumb,
  },
  computed: {
    ...mapState(useAppStore, ["sidebar", "device"]),
    ...mapState(useUserStore, ["avatar", "user"]),
  },
  methods: {
    handleToggleDialog() {
      this.codeFlag = !this.codeFlag;
    },
    hideDialog() {
      this.codeFlag = false;
    },
    confirmDialog() {
      this.hideDialog();
      // 这里可以添加你要执行的确认逻辑
      console.log("确认按钮被点击");
    },
    toggleSideBar() {
      let appStore = useAppStore();
      appStore.handleToggleSidebar();
    },
    async logout() {
      let userId = JSON.parse(localStorage.getItem("system_user"))?.id;
      let platform = localStorage.getItem("dashboardPlatform");
      let overviewFlag = localStorage.getItem(`overviewFlag${userId}`);
      let activeName = localStorage.getItem("mad_active_name");
      let GuideStore = localStorage.getItem("VIRTUALHUMANGUIDESTORE");
      sessionStorage.clear();
      localStorage.clear();
      removeToken();
      localStorage.setItem("dashboardPlatform", platform);
      localStorage.setItem(`overviewFlag${userId}`, overviewFlag);
      localStorage.setItem("mad_active_name", activeName);
      localStorage.setItem("VIRTUALHUMANGUIDESTORE", GuideStore);

      let userStore = useUserStore();
      await userStore.handleLogout();
      window.location.reload(true);
      this.$router.push(`/login?redirect=${this.$route.fullPath}`);
    },
  },
};
</script>

<style lang="scss" scoped>

:deep(.mad-severs-dialog) {
  margin: 0;
  position: fixed !important;
  right: 160px !important;
  top: -60px;
  left: initial;

  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
  }
  .mad-main {
    display: flex;
  }
}
</style>
<style scoped>
:deep(.mad-severs-dialog) {
  border-radius: 15px;
}
</style>
