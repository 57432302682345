import { useUserStore } from "@/store/pinia";
export default {
    install : function (Vue, name) {

        Vue.mixin({
            data: function() {
                return {
                    _IsBoke: false,
                    _IsMadhouse:false,
                    _ALLOW_MODULE_MAPPING: {},
                    _ALLOW_AUTH_RULE:[]
                };
            },
            created : function() {

                //如果根 Vue 对象已经包含了权限数据那么无需再次加载
                let userStore = useUserStore()
                let rules = userStore.rules || []

                this._IsMadhouse=userStore.user?.company_en==='madhouse'
                this._IsBoke=userStore.user?.company_en==='boke'
                this._ALLOW_MODULE_MAPPING =  userStore.allows;
                this._ALLOW_AUTH_RULES = rules;
            },

            methods: {
                $auth : function(target) {
                    // return false
                    if(this._ALLOW_AUTH_RULES.includes(target)){
                        return true
                    }
                    return false
                },
                $allow : function(target) {
                    this.$getAllowPromise();

                    for(var allow in this._ALLOW_MODULE_MAPPING) {
                        //忽略大小写对比，如果匹配到那么拥有权限
                        if(target.toLowerCase().indexOf(allow.toLowerCase().replace('.*', '')) === 0) {
                            return true;
                        }
                    }

                    return false;
                },

                $getAllowPromise : function() {
                    if(_.isEmpty(this._ALLOW_MODULE_MAPPING)) {
                        return this.$api('platform.permission').allows().then((data) => {
                            this._ALLOW_MODULE_MAPPING = data;
                            return data;
                        });
                    } else {
                        return new Promise((resolve, reject) => {
                            resolve(this._ALLOW_MODULE_MAPPING);
                        }).then((data) => {
                            return data;
                        })
                    }
                }
            }
        })
    }
}
