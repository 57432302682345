import _ from "lodash";

export default function () {

  //触发显示的计算列进行计算
  this.showFormulaColumnsEval('data')

  //如果数据已经载入那么构造筛选条件
  _.forEach(this.tablePlugins.filter, (filter, column) => {

    //如果配置为对象，那么采用新模式
    if(_.isObject(filter)) { //如果是配置对象那么
      // filter  = _.assign({
      //   options: undefined,
      //   value: undefined,
      //   multiple: false,
      //   clearable: true,
      // }, filter)

      //如果是自动模式，那么根据数据自动过滤
      //当配置该字段为布尔值 true的时候才启用自动数据聚合
      if(filter?.options === true) {
        //如果有该字段有 format 那么优先在筛选中使用 format 后的数据
        let vals = _.uniq(
          _.map(
            this.rows,
            this.tablePlugins.format[column] ? column + "_format" : column
          )
        );
        let opts = [];
        for (var i in vals) {
          opts.push({ label: vals[i], value: vals[i] });
        }
        this.filterOptions[column] = opts;
      } else if (_.isArray(filter?.options)) {
        //如果过滤配置参数是数组那么使用外部配置的参数
        this.filterOptions[column] = filter.options;
      } else if (_.isFunction(filter?.options)) {
        //如果过滤配置参数是方法那么，传入该列数据并执行
        this.filterOptions[column] = filter.options(_.map(this.rows, column));
      }

      //如果缺省值已复制那么设置过滤缺省值
      if (filter?.value != undefined) {
        this.$set(this.query.columns, column, filter.value);
      }


      //如果缺省值已复制那么设置过滤缺省值
      if (filter?.range != undefined) {
          // console.log(filter);
          if (filter.range === true) {
            this.filters.ranges[column] = {
              slider: false,
            };
          } else if (filter.range === "slider") { //如果滑条模式
            let columnData = _.map(this.rows, (row) => {
              return Number(row[column]);
            });
            this.filters.ranges[column] = {
              min: _.min(columnData),
              max: _.max(columnData),
              slider: true,
            };
          } else if (_.isFunction(filter.range)) { //如果方法模式
            //如果过滤配置参数是方法那么，传入该列数据并执行
            this.filters.ranges[column] = filter.range(_.map(this.rows, column));
          }
      }

    } else if (filter === true) { //////////////////////////////////以下为兼容模式
      //如果有该字段有 format 那么优先在筛选中使用 format 后的数据
      let vals = _.uniq(
        _.map(
          this.rows,
          this.tablePlugins.format[column] ? column + "_format" : column
        )
      );
      let opts = [];
      for (var i in vals) {
        opts.push({ label: vals[i], value: vals[i] });
      }
      this.filterOptions[column] = opts;
    } else if (_.isArray(filter)) {
      //如果过滤配置参数是数组那么使用外部配置的参数
      this.filterOptions[column] = filter;
    } else if (_.isFunction(filter)) {
      //如果过滤配置参数是方法那么，传入该列数据并执行
      this.filterOptions[column] = filter(_.map(this.rows, column));
    }

    //快捷筛选模式 字段
    if (this.query.columns[column] == undefined) {
      this.$set(this.query.columns, column, "");
    }
  });
}
