<template>
  <div>
    <template v-if="hasOneShowingChild(item.children, item)">
      <el-menu-item :index="item.newLink" @click="handleActiveRouter(item)">
        <i class="el-menu-icon" :class="item.icon"></i>
        <span slot="title">{{ item.name }}</span>
      </el-menu-item>
    </template>
    <el-submenu v-else :index="item.id">
      <template slot="title">
        <i class="el-menu-icon" :class="item.icon"></i>
        <span>{{ item.name }}</span>
      </template>
      <sidebar-item
        v-for="child in item.children"
        :key="child.id"
        :item="child"
      />
    </el-submenu>
  </div>
</template>

<script>
export default {
  name: "SidebarItem",
  props: {
    // route object
    item: {
      type: Object,
      required: true,
    },
    isNest: {
      type: Boolean,
      default: false,
    },
    basePath: {
      type: String,
      default: "",
    },
  },
  data() {
    // To fix https://github.com/PanJiaChen/vue-admin-template/issues/237
    // TODO: refactor with render function
    this.onlyOneChild = null;
    return {};
  },
  methods: {
    hasOneShowingChild(children = [], parent) {
      const showingChildren = children.filter((item) => {
        if (item.hidden) {
          return false;
        } else {
          // Temp set(will be used if only has one showing child)
          this.onlyOneChild = item;
          return true;
        }
      });

      // When there is only one child router, the child router is displayed by default

      // if (showingChildren.length === 1) {
      //   return true;
      // }

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        this.onlyOneChild = { ...parent, path: "", noShowingChildren: true };
        return true;
      }

      return false;
    },
    handleActiveRouter(item) {
      const route = this.$route;
      let { path } = route;
      let { newLink } = item;

      if (path == newLink) {
        return;
      }
      this.$router.push({ path: newLink || "/" });
    },
  },
};
</script>
