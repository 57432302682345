
/**
 * @export 设置后台返回表单数据
 * @param {*} arr 请求的table数据
 * @param {*} groups 分类条件 - ['gender', 'age', 'compaignid'...] 最多5个元素
 * @param {function} compute 对列进行计算的函数
 * @param {bool} showtableList 是否直接显示原始数据
 * @param {bool} merge 是否对于底层只有一行的记录合并到上层 true 和并 false 不合并
 * @returns result 期望的数据
 */

 export default function (arr, groups, compute = {}, showtableList = false, merge = true) {
  function guid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  let groupby = function (data, i) {
    const all = 'All'
    let grouped = {}
    let parent = {}
    let compute_keys = {}
    let compute_data = {}
    data.forEach(function (item) {
      let group = item[groups[i]] // group = item['gender'] => '男'
      grouped[group] = grouped[group] || [];
      grouped[group].push(item)
      // key 需要计算的字段名，如cost
      Object.keys(compute).map(key => {
        // if (item[key] || item[key] === 0) { 没有数据或者为0也要分配汇总函数
          compute_keys[key] = compute_keys[key] || []
          compute_keys[key][group] = compute_keys[key][group] || []
          compute_keys[key][group].push(item[key])
        // }
      })
      compute_data[group] = compute_data[group] || {}
      for (let k in item) {
        compute_data[group][k] = compute_data[group][k] || []
        compute_data[group][k].push(item[k])
      }
      // compute_data{ '男': { cost: [100], imp: [200]}}
    })
    // 生成父级数据逻辑
    let keys = [] // 数据行所有的字段列表
    for (let key in grouped) { // key 为groupby的值，如：男
      let row = grouped[key][0]
      parent[key] = { ...row }
      if (grouped[key].length >= 1) {
        keys = Object.keys(row)
        for (let k in row) {
          let kk = k.substring(0, k.lastIndexOf('_format')) || k
          if (groups.indexOf(kk) == -1 || groups.indexOf(kk) > i) {
            parent[key][k] = all
          }
        }
      }

      Object.keys(compute_keys).map(ckey => {
        // ckey: 计算的字段名，key: 分组的值，如：男
        // console.log(1213,' compute[ckey]',key,compute_keys[ckey], compute_keys[ckey][key], compute_data[key])
        let temp=compute_keys[ckey][key] ? compute_keys[ckey][key] :[]
        parent[key][ckey] = compute[ckey](temp, compute_data[key])
        // if (JSON.stringify(keys)!=="{}" && keys.indexOf(`${ckey}_format`) != -1) {
        //   parent[key][`${ckey}_format`] = parent[key][ckey]
        // }
      })
      parent[key].rowkey = guid();
    }
    return { data_list: grouped, parents: parent };
  }
  function group_rows(data, i, result) {
    let { data_list, parents } = groupby(data, i)
    for (let key in data_list) {
      let row = data_list[key]
      let parent_row = parents[key]
      let child = { ...parent_row, children: [] }
      if (i < groups.length - 1) {
        group_rows(row, i + 1, child.children)
      } else {
        child.children = row

        //如果合并树的最底层数据，并且底层数据只有一行的话，那就合并到本层
        if(merge && row.length == 1) {
          child = row[0]
        }
      }

      if (child.children && child.children.length < 1) {
        delete child.children
      }
      result.push(child)
    }
  }

  if (groups.length < 1) {
    return arr
  }
  //预定义结果数组
  let result = [];
  if (showtableList) {
    return arr;
  }

  for (let index = 0; index < arr.length; index++) {
    if (arr[index].rowkey)  break;
    arr[index].rowkey = guid();
  }
  // console.log(JSON.stringify(arr))
  // return
  group_rows(arr, 0, result);
  return result;
}
