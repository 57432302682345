import csv from "csv.js";
import filesaver from "file-saver";
import { formats } from "numeral";
var csvData = [];
export default function () {
  let filteredData = this.tableAllRows;
  // _.forEach(this.tableShowColumns, (field, index) => {
  for(var field of this.tableShowColumns) {
    let column = typeof field == "object" ? field.column : field;
    if (
      // !this.tablePlugins.hidden[column]
      !this.tablePlugins.hidden[column] &&
      !_.includes(column, "_format")
    ) {
      let indexCsv = [0];
      nestedChild(filteredData, column, this.tableColumnNames, indexCsv, this.cellIterator);
    }
  }
  // });

  if(_.isFunction(this.tablePlugins.export)) {
    this.$prompt('请输入导出文件名', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      inputValue: this.tablePlugins.export()
    }).then(({ value }) => {
      let file = new File(['\ufeff' + csv.encode(csvData)], `${value}_${this.$moment().format('YYYYMMDDHHmmss')}.csv`, {
        type: "text/csv;charset=utf-8",
      });
      csvData = []
      filesaver.saveAs(file);
    }).catch(() => {
    });
  } else {
    let file = new File(['\ufeff' + csv.encode(csvData)], `${this.$moment().format('YYYYMMDDHHmmss')}.csv`, {
      type: "text/csv;charset=utf-8",
    });
    csvData = []
    filesaver.saveAs(file);
  }
}

let aHead = ['账户ID','广告系列ID','广告组ID','广告ID','素材ID','活动ID','创意ID','卡片ID','广告系列组ID','关键词ID','CampaignSetID','CampaignID','CreativePackID'];
function nestedChild(rows, column, columnNames, index, cellIterator) {
  for (var row in rows) {
    if (!csvData[index[0]]) {
      csvData[index[0]] = {};
    }

    var cellData = cellIterator(rows[row], column)
    // var cellData = rows[row][column + "_format"]
    //   ? rows[row][column + "_format"]
    //   : rows[row][column];

    // //为了应对性能问题 format 方法实时运算而非提前递归运算
    // var cellData = format?.[column]
    //   ? format[column](rows[row][column], row)
    //   : rows[row][column];

    var heading = columnNames[column];
    if (aHead.indexOf(heading) > -1) {
      csvData[index[0]][heading] = '"\t' + cellData + '"';
    } else {
      csvData[index[0]][heading] = cellData;
    }

    index[0]++;
    if (rows[row]["children"]) {
      nestedChild(rows[row]["children"], column, columnNames, index, cellIterator);
    }
  }
}
