import request from '@/utils/request'




export function HttpBackendValidate(params) {
  return request({
    url: 'platform/api/modelapi/LoginForm/backendValidate',
    method: 'get',
    data: params
  })
}


export function httpIn(params) {
  return request({
    url: '/platform/sign/PlatformApi/method/in',
    method: 'post',
    data: params
  })
}

export function httpMessages(params) {
  return request({
    url: 'platform/language/PlatformApi/method/messages',
    method: 'post',
    data: params
  })
}

export function httpGet(params) {
  return request({
    url: '/platform/const/PlatformApi/method/get',
    method: 'post',
    data: params
  })
}


export function httpAllows(params) {
  return request({
    // url: '/platform/permission/PlatformApi/method/allows',
    url: 'api/manage/permission/tmp_allow_list',
    method: 'post',
    data: params
  })
}


export function httpModules(params) {
  return request({
    url: '/platform/permission/PlatformApi/method/modules',
    method: 'post',
    data: params
  })
}

export function httpUser(params) {
  return request({
    url: '/platform/permission/PlatformApi/method/user',
    method: 'post',
    data: params
  })
}


// 以下是新的接口

export function httpSignIn(params) {
  return request({
    url: '/api/manage/sign/in',
    method: 'post',
    data: params
  })
}
export function httpPermissionModulesLogin(params) {
  return request({
    url: 'api/manage/permission/modules_login',
    method: 'post',
    data: params
  })
}



export function httpPermissionModules(params) {
  return request({
    url: '/api/manage/permission/modules',
    method: 'post',
    data: params
  })
}

export function httpPermissionAllows(params) {
  return request({
    // url: '/api/manage/permission/allows',
    url: '/api/manage/permission/tmp_allow_list',
    method: 'post',
    data: params
  })
}


export function httpPermissionGet(params) {
  return request({
    url: '/api/manage/permission/get',
    method: 'post',
    data: params
  })
}

export function httpPermissionMessages(params) {
  return request({
    url: '/api/manage/permission/messages',
    method: 'post',
    data: params
  })
}

//登录账号包含的分享账号列表
export function httpGetUserShareAccountList(params) {
  return request({
    url: '/api/manage/user/share_account_list',
    method: 'get',
    params
  })
}

//项目列表
export function httpGetProjectOwnList(params) {
  return request({
    url: '/api/ads/project/ownList',
    method: 'post',
    data: params
  })
}

export function httpProjectCohortEditList(params) {
  return request({
    url: 'api/ads/project/cohortEditList',
    method: 'post',
    data:params
  })
}

export function httpAdAccountProjectList(params) {
  return request({
    url: 'api/ads/adaccount/projectList',
    method: 'post',
    data:params
  })
}

export function httpProjectThirdEditList(params) {
    return request({
        url: 'api/ads/project/thirdEditList',
        method: 'post',
        data:params
    })
}

export function httpProjectShopList(params) {
  return request({
    url: 'api/ads/project/shopList',
    method: 'post',
    data:params
  })
}

//项目列表查询
export function httpGetProjectOwnAndShareList(params) {
  return request({
    url: '/api/ads/project/ownAndShareList',
    method: 'post',
    data:params
  })
}

export function httpGetProjectOrgList(params) {
  return request({
    url: '/api/ads/project/orgList',
    method: 'get',
    params
  })
}

export function httpGetProjectShareAccountList(params) {
  return request({
    url: '/api/ads/project/shareAccountList',
    method: 'get',
    params
  })
}

export function httpGetUserInfo(params) {
  return request({
    url: 'api/manage/user/detail',
    method: 'get',
    params
  })
}

export function httpSignAutoLogin(params) {
  return request({
    url: 'api/manage/sign/autoLogin',
    method: 'post',
    data:params
  })
}

