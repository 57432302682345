<style lang="scss">
.platform-panel {
	header {
		label {
			font-weight: 600;
		    margin: 1em 0;
		    display: block;
		}

		hr {
		    margin: 1em 0;
		    padding: 0;
		    height: 1px;
		    border: 0;
		    border-top: 1px solid #eaeaea;
		}
	}
}
</style>

<template>
<div class="platform-panel">
	<header>
		<label>{{label}}</label>
		<hr>
	</header>
	<div>
		<slot></slot>
	</div>
</div>
</template>

<script>
export default {
    props: {
      label: {
        type: String,
        required: true
      },
    }
}
</script>
