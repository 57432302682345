import _ from "lodash";

function isEmpty(val) {
  if (val == undefined || val == null || val === '') {
    return true
  }
  return false
}

//给数据集中每个对象新增或覆盖，新的数据集
function collectionEnhance(list, source) {
  return _.map(list, function(element) { return _.assign({}, element, source); });
}

export {
  isEmpty,
  collectionEnhance
}

