export default {
    install : function (Vue, name) {
    
        Vue.mixin({
            data: function() {
                return {
                    _PLATFORM_CONSTS: {}
                }; 
            },
            methods: {
                $const : function(type, name = null) {
                    this.$getAllowPromise();
                    this._PLATFORM_CONSTS[type] = this._PLATFORM_CONSTS[type] || {}
                    return name ? this._PLATFORM_CONSTS[type][name] : this._PLATFORM_CONSTS[type];
                },
                
                $getConstPromise : function() {
                    if(_.isEmpty(this._PLATFORM_CONSTS)) {
                        return this.$api('platform.const').all().then((data) => {
                            this._PLATFORM_CONSTS = data;
                            return data;
                        });
                    } else {
                        return new Promise((resolve, reject) => {
                            resolve(this._PLATFORM_CONSTS);
                        }).then((data) => {
                            return data;
                        })
                    }
                }
            }
        })
    }
}