import axios from "axios";
import { Message } from "element-ui";

import { getToken } from "@/utils/auth";
import { getDomain } from "@/utils/util";
import { Loading } from "element-ui";
import Vue from "vue";
Vue.use(Loading);
let loading;

// create an axios instance
const service = axios.create({
  baseURL: getDomain(), // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 300000, // request timeout
});

let cancelList = [];

const noLoadingAPI = [
  "api/manage/user/getBindInfo",
  "madapi/material/get_disk_media_customers.php",
  "api/ads/tiktok/TiktokCohort/cohortAccountGroupList",
  "madapi/dataai/api_ai_meta_apps_market_ranking_search.php",
  "api/facebook/switchStatusList",
  "api/report/openapi/3rd/af_apps",
  "api/report/openapi/3rd/af_cohort_chart",
  "/api/manage/folder/authProjectList",
  "/api/ads/tiktok/have/audience",
  "/api/ads/tiktok/fetch/locations",
  "/api/platform/user/updateUserHeader",
  "api/tiktok/application/save_info_tmp",
  "api/ads/tiktok/get/audience",
  "/api/ads/tiktok/get/audience",
  "/api/ads/report/Shopify/getOrderlList",
  '/api/ads/tiktok/spark/importRcfFile'
];
const noCheckCodeAPI = ["https://beta.kolsolution.com/"];
const noCheckCodeUrlAPI = "wh_api";
const noCheckURLs = [
  "/madapi/material/manage_disk_media.php",
  "/api/ads/report/Shopify/addShop",
  "/api/platform/user/updateUserHeader",
  "/api/platform/user/copy_report",
  "/api/platform/user/addHeader",
  "/api/platform/user/updateUserHeader",
  "/api/ads/tiktok/spark/import",
  "/api/ads/tiktok/spark/importFile",
  "/api/ads/facebook/uploadPic",
  "/api/ads/tiktok/translate/upload",
  "madapi/material/manage_disk_media.php?type=save",
  "madapi/chatgpt/generate_img_comfyui.php",
  "madapi/chatgpt/ai_paperwork.php",
];

import { useSettingsStore, useUserStore } from "@/store/pinia";

var needLoadingRequestCount = 0;

function showFullScreenLoading(url) {
  if (noLoadingAPI.includes(url)) {
    return;
  }
  if (needLoadingRequestCount === 0) {
    loading = Loading.service({
      lock: true,
      target: ".main-container",
      text: "拼命加载中...",
      fullscreen: true,
      background: "rgba(255,255,255,.7)",
    });
    let settingsStore = useSettingsStore();
    settingsStore.handleIsLoading(true);
  }
  needLoadingRequestCount++;
}

function tryHideFullScreenLoading() {
  if (needLoadingRequestCount <= 0) {
    let settingsStore = useSettingsStore();
    settingsStore.handleIsLoading(false);
    loading?.close();
    return;
  }
  needLoadingRequestCount--;
  if (needLoadingRequestCount === 0) {
    let settingsStore = useSettingsStore();
    settingsStore.handleIsLoading(false);
    loading?.close();
  }
}

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent
    config.cancelToken = new axios.CancelToken(function (c) {
      cancelList.push(c);
    });
    let { url } = config;
    config.headers["x-user-token"] = getToken();
    config.headers["versions"] = "1.0";
    showFullScreenLoading(url);
    return config;
  },

  (error) => {
    // do something with request error

    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    tryHideFullScreenLoading();
    const res = response.data;

    let baseURL = response.config.baseURL;
    let url = response.config.url;

    // 兼容后端 做特殊处理
    if (
      noCheckCodeAPI.includes(baseURL) ||
      url.includes(noCheckCodeUrlAPI) ||
      noCheckURLs.some((noCheckURL) => url.includes(noCheckURL))
    ) {
      return res;
    }
    if (res.code == 0) {
      return res?.data;
    }

    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 200) {
      // 判断是否是201 文件不存在 属于正常
      if (res.message == "文件不存在!" && res.code == 201) {
        return Promise.resolve(res);
      }
      //  需要重新登录
      if (res.code === 4001) {
        // to re-login
        let userStore = useUserStore();
        userStore.handleResetToken().then(() => {
          location.reload();
        });
      }
      if (res.code !== 4001) {
        Message({
          message: res?.message || res?.error || "Error",
          type: "error",
          duration: 3 * 1000,
        });
      }

      return Promise.reject(res);
    } else {
      return res?.data;
    }
  },
  (error) => {
    cancelList.forEach((c) => {
      c();
    });
    needLoadingRequestCount = 0;
    tryHideFullScreenLoading();
    Message({
      message: error?.message,
      type: "error",
      duration: 3 * 1000,
    });
    return Promise.reject(error);
  }
);

export default service;
