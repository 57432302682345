//接口内容传输加密类
class Encrypt {
    secret (secret) {
        this.secret = secret;
        return this;
    }

    encode () {
        console.log('Encrypt encode');
    }

    decode () {
        console.log('Encrypt decode');
    }
}

export default Encrypt;